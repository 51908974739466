.NotificationBanner__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media (max-width: 768px) {
  .NotificationBanner__container {
    left: 0;
    width: 100%;
    /*top: 66px;*/
  }
}

.NotificationBanner__card {
  padding: 16px;
  border-radius: 0px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  background: white;
  animation: slideIn 0.3s ease-out;
}

@media (max-width: 768px) {
  .NotificationBanner__card {
    border-radius: 0px;
  }
}

.NotificationBanner__card.warning {
  border-left: 4px solid #faad14;
}

.NotificationBanner__card.error {
  border-left: 4px solid #ff4d4f;
}

.NotificationBanner__card.info {
  border-left: 8px solid var(--green);
  border-right: 8px solid var(--green);
}
@media (max-width: 768px) {
  .NotificationBanner__card.info {
    border-left: 4px solid var(--green);
    border-right: 4px solid var(--green);
  }
}

.NotificationBanner__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.NotificationBanner__header h4 {
  margin: 0;
  font-weight: 500;
}

.NotificationBanner__actions {
  display: flex;
  gap: 8px;
}

.NotificationBanner__acknowledge {
  padding: 4px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
}

.NotificationBanner__acknowledge:hover {
  background: #f5f5f5;
}

.NotificationBanner__dismiss {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 4px 8px;
  font-size: 16px;
  opacity: 0.6;
}

.NotificationBanner__dismiss:hover {
  opacity: 1;
}

.NotificationBanner__content p {
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
