.OrderGenerator__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.OrderGenerator__backButtonContainer {
  position: relative;
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  height: 35px;
}

.OrderGenerator__backButtonContainer svg {
  margin-right: 20px;
}

.OrderGenerator__HeadingsContainer {
  position: relative;
}

.OrderGenerator__HeadingsContainer h1,
.OrderGenerator__packageIDSelectionHeading {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  color: var(--jfcBlack);
}

.OrderGenerator__HeadingsContainer h1 {
  font-size: 24px;
}

.OrderGenerator__PackageIDSelectionHeading {
  position: absolute;
  left: 0;
  top: 24px;
  font-size: 20px;
}

.OrderGenerator__PackageIDSelectionHeading--hide {
  display: none;
}

.OrderGenerator__alertContainer {
  padding: 24px;
}

.OrderGenerator__spin {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
