.column {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.headerRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.h1_heading {
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 49px;
  color: #454b60;
}

.h2_heading {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #121a2d;
  margin: 5px 0;
}

.h2_heading span {
  color: #089bab;
}

.h1_heading span {
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 49px;
  color: #089bab;
  margin: 0 10px;
}

.backButton {
  cursor: pointer;
  margin-right: 10px;
}

.tray-picklist-table-wrapper tbody {
  padding: 0;
  margin: 0;
}

.tray-picklist-table-wrapper tr,
.tray-picklist-table-wrapper td {
  vertical-align: middle;
  padding: 0;
  margin: 0;
}

.DescriptionParagraph {
  margin-top: -5px;
  font-size: 12px;
  color: #a0a0a0;
}

.table {
  width: 100%; /* Makes the table take up the full width of the container */
  border-collapse: collapse;
}
