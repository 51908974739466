.PDFReportViewer__fontSizeRadioGroupContainer::before {
  content: "|";
  padding-right: 34px;
}

.PDFReportViewer__fontSizeRadioGroupContainer {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
