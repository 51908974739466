.DailyTotalBarChart__allDaysSelected #tray_Series_0_Point_0 {
  opacity: 1;
}
.DailyTotalBarChart__allDaysSelected #tray_Series_0_Point_1 {
  opacity: 1;
}
.DailyTotalBarChart__allDaysSelected #tray_Series_0_Point_2 {
  opacity: 1;
}
.DailyTotalBarChart__allDaysSelected #tray_Series_0_Point_3 {
  opacity: 1;
}
.DailyTotalBarChart__allDaysSelected #tray_Series_0_Point_4 {
  opacity: 1;
}
.DailyTotalBarChart__allDaysSelected #tray_Series_0_Point_5 {
  opacity: 1;
}
.DailyTotalBarChart__allDaysSelected #tray_Series_0_Point_6 {
  opacity: 1;
}

.DailyTotalBarChart__SundaySelected #tray_Series_0_Point_0 {
  opacity: 1;
}
.DailyTotalBarChart__SundaySelected #tray_Series_0_Point_1 {
  opacity: 0.3;
}
.DailyTotalBarChart__SundaySelected #tray_Series_0_Point_2 {
  opacity: 0.3;
}
.DailyTotalBarChart__SundaySelected #tray_Series_0_Point_3 {
  opacity: 0.3;
}
.DailyTotalBarChart__SundaySelected #tray_Series_0_Point_4 {
  opacity: 0.3;
}
.DailyTotalBarChart__SundaySelected #tray_Series_0_Point_5 {
  opacity: 0.3;
}
.DailyTotalBarChart__SundaySelected #tray_Series_0_Point_6 {
  opacity: 0.3;
}

.DailyTotalBarChart__MondaySelected #tray_Series_0_Point_0 {
  opacity: 0.3;
}
.DailyTotalBarChart__MondaySelected #tray_Series_0_Point_1 {
  opacity: 1;
}
.DailyTotalBarChart__MondaySelected #tray_Series_0_Point_2 {
  opacity: 0.3;
}
.DailyTotalBarChart__MondaySelected #tray_Series_0_Point_3 {
  opacity: 0.3;
}
.DailyTotalBarChart__MondaySelected #tray_Series_0_Point_4 {
  opacity: 0.3;
}
.DailyTotalBarChart__MondaySelected #tray_Series_0_Point_5 {
  opacity: 0.3;
}
.DailyTotalBarChart__MondaySelected #tray_Series_0_Point_6 {
  opacity: 0.3;
}

.DailyTotalBarChart__TuesdaySelected #tray_Series_0_Point_0 {
  opacity: 0.3;
}
.DailyTotalBarChart__TuesdaySelected #tray_Series_0_Point_1 {
  opacity: 0.3;
}
.DailyTotalBarChart__TuesdaySelected #tray_Series_0_Point_2 {
  opacity: 1;
}
.DailyTotalBarChart__TuesdaySelected #tray_Series_0_Point_3 {
  opacity: 0.3;
}
.DailyTotalBarChart__TuesdaySelected #tray_Series_0_Point_4 {
  opacity: 0.3;
}
.DailyTotalBarChart__TuesdaySelected #tray_Series_0_Point_5 {
  opacity: 0.3;
}
.DailyTotalBarChart__TuesdaySelected #tray_Series_0_Point_6 {
  opacity: 0.3;
}

.DailyTotalBarChart__WednesdaySelected #tray_Series_0_Point_0 {
  opacity: 0.3;
}
.DailyTotalBarChart__WednesdaySelected #tray_Series_0_Point_1 {
  opacity: 0.3;
}
.DailyTotalBarChart__WednesdaySelected #tray_Series_0_Point_2 {
  opacity: 0.3;
}
.DailyTotalBarChart__WednesdaySelected #tray_Series_0_Point_3 {
  opacity: 1;
}
.DailyTotalBarChart__WednesdaySelected #tray_Series_0_Point_4 {
  opacity: 0.3;
}
.DailyTotalBarChart__WednesdaySelected #tray_Series_0_Point_5 {
  opacity: 0.3;
}
.DailyTotalBarChart__WednesdaySelected #tray_Series_0_Point_6 {
  opacity: 0.3;
}

.DailyTotalBarChart__ThursdaySelected #tray_Series_0_Point_0 {
  opacity: 0.3;
}
.DailyTotalBarChart__ThursdaySelected #tray_Series_0_Point_1 {
  opacity: 0.3;
}
.DailyTotalBarChart__ThursdaySelected #tray_Series_0_Point_2 {
  opacity: 0.3;
}
.DailyTotalBarChart__ThursdaySelected #tray_Series_0_Point_3 {
  opacity: 0.3;
}
.DailyTotalBarChart__ThursdaySelected #tray_Series_0_Point_4 {
  opacity: 1;
}
.DailyTotalBarChart__ThursdaySelected #tray_Series_0_Point_5 {
  opacity: 0.3;
}
.DailyTotalBarChart__ThursdaySelected #tray_Series_0_Point_6 {
  opacity: 0.3;
}

.DailyTotalBarChart__FridaySelected #tray_Series_0_Point_0 {
  opacity: 0.3;
}
.DailyTotalBarChart__FridaySelected #tray_Series_0_Point_1 {
  opacity: 0.3;
}
.DailyTotalBarChart__FridaySelected #tray_Series_0_Point_2 {
  opacity: 0.3;
}
.DailyTotalBarChart__FridaySelected #tray_Series_0_Point_3 {
  opacity: 0.3;
}
.DailyTotalBarChart__FridaySelected #tray_Series_0_Point_4 {
  opacity: 0.3;
}
.DailyTotalBarChart__FridaySelected #tray_Series_0_Point_5 {
  opacity: 1;
}
.DailyTotalBarChart__FridaySelected #tray_Series_0_Point_6 {
  opacity: 0.3;
}

.DailyTotalBarChart__SaturdaySelected #tray_Series_0_Point_0 {
  opacity: 0.3;
}
.DailyTotalBarChart__SaturdaySelected #tray_Series_0_Point_1 {
  opacity: 0.3;
}
.DailyTotalBarChart__SaturdaySelected #tray_Series_0_Point_2 {
  opacity: 0.3;
}
.DailyTotalBarChart__SaturdaySelected #tray_Series_0_Point_3 {
  opacity: 0.3;
}
.DailyTotalBarChart__SaturdaySelected #tray_Series_0_Point_4 {
  opacity: 0.3;
}
.DailyTotalBarChart__SaturdaySelected #tray_Series_0_Point_5 {
  opacity: 0.3;
}
.DailyTotalBarChart__SaturdaySelected #tray_Series_0_Point_6 {
  opacity: 1;
}
