.imageSelector__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100px;
  height: auto;
}

@media (max-width: 640px) {
  .imageSelector__container {
    width: 320px;
    min-width: 320px;
    margin-bottom: 24px;
  }
}

.imageSelector__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
}

@media (max-width: 640px) {
  .imageSelector__header {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.imageSelector__col {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.imageSelector__title {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #089bab;
}

.imageSelector__dropContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  border: 1px dashed #778ca2;
  border-radius: 8px;
}

@media (max-width: 640px) {
  .imageSelector__dropContainer {
    height: 100%;
    border: none;
  }
}

.imageSelector__text {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #778ca2;
}

.imageSelector__cardContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-height: 200px;
  overflow: auto;
}

.imageSelector__card {
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 5px 14px rgba(120, 146, 141, 0.05);
}

@media (max-width: 640px) {
  .imageSelector__card {
    height: 100%;
  }
}

.imageSelector__cardHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 40px;
}

.imageSelector__cardBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  /* height: 50px; */
  margin-bottom: 10px;
}

@media (max-width: 640px) {
  .imageSelector__cardBody {
    margin-bottom: 0;
  }
}

.imageSelector__cardImage {
  width: 80px;
  /* height: 80px; */
}
