.ant-select-item {
  margin-top: 2px;
  margin-bottom: 2px;
  border-start-start-radius: 6px !important;
  border-start-end-radius: 6px !important;
  border-end-start-radius: 6px !important;
  border-end-end-radius: 6px !important;
}

.ant-select-item-option:not(.ant-select-item-option-disabled):not(.ant-select-item-option-selected):hover {
  background-color:  rgba(0, 0, 0, 0.04) !important;
}