.listItemsContainer {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 640px) {
  .listItemsContainer {
    flex: 1 0 auto;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media only screen and (min-width: 641px) {
  .listItemsContainer {
    align-items: center;
    width: 100%;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 40px;
    box-sizing: border-box;
  }
}

.listItems {
  list-style-type: none;
  background-color: inherit;
  width: inherit;
  padding: 0;
  margin: 0;
}

@media only screen and (min-width: 641px) {
  .listItems {
    margin-top: 16px;
  }
}

.scrollArea {
  overflow-x: hidden;
  overflow-y: auto;
}

@media only screen and (max-width: 640px) {
  .scrollArea {
    height: 0;
    flex: 1 0 auto;
    padding: 4px 3px 3px 3px;
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }

  .scrollArea::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (min-width: 641px) {
  .scrollArea {
    height: 100%;
    max-height: 260px;
    padding: 4px 10px 3px 3px;
    scroll-padding: 0 0 0 3px;
  }
}

.listItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 80px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 0;
  margin: 0;
  background-color: #fff;
}

.listItem--active {
  border: 1px solid #089bab;
}

.listItem:not(:last-child) {
  margin-bottom: 10px;
}

.stockLocationName {
  align-self: flex-start;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
  color: #093a64;
  font-weight: 500;
  font-size: 16px;
}

@media only screen and (max-width: 640px) {
  .stockLocationName {
    margin-top: 12px;
  }
}

@media only screen and (min-width: 641px) {
  .stockLocationName {
    margin-bottom: 0;
  }
}

.imageContainer {
  width: 57px;
  overflow: none;
  margin-left: 10px;
  margin-right: 11px;
  flex-shrink: 0;
  flex-grow: 0;
}

@media only screen and (min-width: 641px) {
  .imageContainer {
    margin-right: 13px;
  }
}

.imageContainer--default {
  border: 2px solid #a7bbcf;
  box-sizing: border-box;
  padding: 1.5px;
}

.image {
  width: 57px;
  height: auto;
  max-height: 57px;
}

.descriptionGroup {
  overflow: hidden;
}

.productDesc {
  font-family: "Poppins", sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.packageDesc {
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0px;
  color: #87909e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.partialAndFullBtnGroup {
  padding: 10px;
  padding-bottom: 0;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgb(0 0 0 / 10%);
  box-sizing: border-box;
  border-radius: 6px;
}

@media only screen and (min-width: 641px) {
  .partialAndFullBtnGroup {
    margin-left: 60px;
    margin-right: 60px;
    margin-top: 40px;
    margin-bottom: 43px;
    padding: 30px 20px;
  }
}

.partialAndFullMsg {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #363f4c;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

@media only screen and (min-width: 641px) {
  .btnGroup {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

.stockedItemQtyGroup {
  font-family: "SF Pro Display", sans-serif;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  align-items: center;
  width: 105px;
  height: 64px;
  border-radius: 6px;
  background-color: #f2f4f6;
  margin-right: 8px;
  margin-left: 3px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: auto;
  position: relative;
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 200;
}

.coverError {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 200;
  width: 100px;
  height: 64px;
}

.stockedItemQtyGroup--smart {
  justify-content: center;
}

.stockedItemQtyGroup--smart p:first-child {
  padding-top: 2px;
  padding-bottom: 2px;
  margin: 0;
}

.stockedItemQtyGroup--smart p:last-child {
  padding-bottom: 2px;
  padding-top: 2px;
  margin: 0;
}

.stockedItemQtyNumOfItems {
  font-size: 15px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0px;
  color: #87909e;
}

.messageContainer {
  padding: 10px;
  margin-top: 16px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgb(0 0 0 / 10%);
  box-sizing: border-box;
  border-radius: 6px;
}

@media only screen and (min-width: 641px) {
  .messageContainer {
    padding: 30px 20px;
    margin-top: 40px;
    margin-left: 60px;
    margin-right: 60px;
    margin-bottom: 43px;
  }
}

.message {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #363f4c;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

@media only screen and (min-width: 641px) {
  .message {
    margin-bottom: 20px;
  }
}

.doneButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-left: 18px;
  margin-right: 18px;
}
