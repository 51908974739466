.DispensedMedicationsTable__tableContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 22px;
  box-sizing: border-box;
  width: 85vw;
}

.DispensedMedicationsTable__tableTitle {
  line-height: 1;
  font-family: "Poppins", sans-serif;
  padding: 10px 0 20px 0;
  margin: 0;
  text-align: center;
}

.DispensedMedicationsTable__tableTitleMain,
.DispensedMedicationsTable__tableTitleSub {
  line-height: 1;
  font-family: "Poppins", sans-serif;
  margin: 0;
  text-align: center;
  margin: 0;
}

.DispensedMedicationsTable__tableTitleMain {
  font-size: 18px;
  font-weight: 500;
  padding: 0 0 4px 0;
}

.DispensedMedicationsTable__tableTitleSub {
  font-size: 12px;
  font-weight: 400;
  padding: 0;
}

.DispensedMedicationsTable__idSquareContainer svg {
  width: 16px;
  fill: transparent;
  stroke: var(--green);
  margin-top: 4px;
}

.DispensedMedicationsTable__idSquareContainer--active svg {
  fill: var(--green);
}

.DispensedMedicationsTable__bold {
  font-weight: 700;
}

.DispensedMedicationsTable__calculationButtoncontainer {
  display: flex;
  justify-content: center;
  margin-top: auto;
}

.DispensedMedicationsTable__SelectTrayPillsForCanisterContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.DispensedMedicationsTable__limitMessage {
  font-size: 12px;
  margin: 5px 0 0 0;
  padding: 0;
  line-height: 0;
}
