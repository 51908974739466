@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap");

:root {
  --smokyAzurite: #708d9e;
  --rhumbaOrange: #cb7841;
  --greyMatter: #a7a8a2;

  --black: #121a2d;
  --blackLight: rgba(0, 0, 0, 0.3);
  --blackVeryLight: rgba(0, 0, 0, 0.2);
  --blackExtraLight: rgba(211, 211, 211, 0.2);
  --green: #089bab;
  --greenDisabled: rgba(8, 155, 171, 0.5);
  --red: red;
  --orange: #ffab2b;
  --gray: #e7e7e8;
  --blueGray: #778ca2;
  --popupTopLg: 2px;
  --popupRightLg: 0px;
  --popupTopMd: -10px;
  --popupRightMd: 10px;
  --popupTopSm: 2px;
  --popupRightSm: 0px;
  --focusColor: #95d4da;
  --focusColorDropDownIcon: #ceebee;
  --jfcGreen: #477343;
  --jfcOrange: #f08319;
  --jfcLightGreen: #8ebe5b;
  --jfcBlack: #2c2c2c;
  --jfcGrey: #536569;
  --jfcLightGrey: #f7fafa;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background: #bbe3e7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  margin: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 27px;
  color: var(--black);
}

.ant-table-cell-row-hover {
  background-color: inherit !important;
}