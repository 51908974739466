.SelectTrayPillsForCanisterTable__AntStyle
  .ant-pagination
  .ant-pagination-item-active,
.SelectTrayPillsForCanisterTable__AntStyle
  .ant-pagination
  .ant-pagination-item-active:hover {
  border-color: var(--green) !important;
}
.SelectTrayPillsForCanisterTable__AntStyle
  .ant-pagination
  .ant-pagination-item-active
  a {
  color: var(--green) !important;
}

.SelectTrayPillsForCanisterTable__AntStyle
  .ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: var(--green) !important;
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1) !important;
  outline: 0 !important;
}

.SelectTrayPillsForCanisterTable__AntStyle
  .ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: var(--green) !important;
}

.SelectTrayPillsForCanisterTable__AntStyle
  .ant-table-wrapper
  .ant-table-column-sorter-up.active,
.SelectTrayPillsForCanisterTable__AntStyle
  .ant-table-wrapper
  .ant-table-column-sorter-down.active {
  color: var(--green) !important;
}

.SelectTrayPillsForCanisterTable__AntStyle table {
  padding-left: 14px;
  padding-right: 14px;
  box-sizing: border-box;
}

.SelectTrayPillsForCanisterTable__AntStyle
  .ant-table-wrapper
  td.ant-table-column-sort {
  background: #fafafa;
}

.SelectTrayPillsForCanisterTable__AntStyle
  .ant-table-body
  > table
  > tbody
  > tr:nth-child(odd) {
  background: #fafafa;
}

.SelectTrayPillsForCanisterTable__AntStyle
  .ant-table-body
  > table
  > tbody
  > tr:nth-child(even) {
  background: #ffffff;
}

.SelectTrayPillsForCanisterTable__AntStyle .ant-table-wrapper .ant-table-cell,
.SelectTrayPillsForCanisterTable__AntStyle
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
.SelectTrayPillsForCanisterTable__AntStyle
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
.SelectTrayPillsForCanisterTable__AntStyle
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td,
.SelectTrayPillsForCanisterTable__AntStyle .ant-table-wrapper tfoot > tr > th,
.SelectTrayPillsForCanisterTable__AntStyle .ant-table-wrapper tfoot > tr > td {
  position: relative;
  padding: 2px 2px;
  overflow-wrap: break-word;
}

.SelectTrayPillsForCanisterTable__AntStyle .ant-table-wrapper .ant-table-cell,
.SelectTrayPillsForCanisterTable__AntStyle
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
.SelectTrayPillsForCanisterTable__AntStyle
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
.SelectTrayPillsForCanisterTable__AntStyle
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td,
.SelectTrayPillsForCanisterTable__AntStyle .ant-table-wrapper tfoot > tr > th,
.SelectTrayPillsForCanisterTable__AntStyle .ant-table-wrapper tfoot > tr > td {
  position: relative;
  padding: 8px 8px;
  overflow-wrap: break-word;
}

.SelectTrayPillsForCanisterTable__AntStyle
  .ant-table-wrapper
  .ant-table-cell:nth-child(odd) {
  background: #fafafa !important;
}

.SelectTrayPillsForCanisterTable__AntStyle
  .ant-table-wrapper
  .ant-table-tbody
  > tr:nth-child(even)
  > td {
  background: #ffffff !important;
}

.SelectTrayPillsForCanisterTable__AntStyle
  .ant-table-wrapper
  .ant-table-tbody
  > tr:nth-child(odd)
  > td {
  background: #fafafa !important;
}

.SelectTrayPillsForCanisterTable__AntStyle .ant-table-wrapper .ant-table-cell {
  background: #fafafa !important;
}
