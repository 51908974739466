.homeView__container--smallScreen {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  box-sizing: border-box;
}

.homeView__view {
  display: flex;
  min-height: 100%;
  height: 100vh;
  min-width: 100%;
  margin: auto 0;
  background-color: white;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .homeView__view {
    display: flex;
    box-sizing: border-box;
  }
}

@media (min-width: 1008px) {
  .homeView__view {
    min-width: 1008px;
    box-sizing: border-box;
  }
}

.homeView__content {
  display: flex;
  flex-direction: column;
}

@media (max-width: 640px) {
  .homeView__content {
    flex-direction: column;
    flex: 1;
    height: 90vh;
  }
}

@media (min-width: 641px) {
  .homeView__content {
    position: relative;
    display: flex;
    flex: 1;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .homeView__content {
    padding: 20px 16px 25px 19px;
    /* width: 100%; */
  }
}

@media (min-width: 1008px) {
  .homeView__content {
    /* padding: 30px 40px; */
    padding: 40px 60px 60px 60px;
    /* width: 100%; */
  }
}

.homeView__navBarContainer {
  background-color: #089bab;
  height: 100%;
}

.homeView__deleteWarningModal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 400px;
  height: 170px;
  border-radius: 5px;
  background-color: white;
  z-index: 10000000;
}

.homeView__deleteWarningModalMessage {
  padding: 24px 18px 24px 18px;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  color: var(--black);
}

.homeView__ModalButtonsGroup {
  display: flex;
  justify-content: center;
  align-items: center;
}
