.ant-btn-variant-solid {
  color: #fff;
  background: #089bab;
}

.ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):active {
  background: #089bab;
  color: #fff;
}

.ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):hover {
  background: #078593;
  color: #fff;
}

.ant-btn-variant-dashed {
  border-color: #d9d9d9;
  color: #121a2d;
  background: rgb(231, 231, 231);
}

.ant-btn-variant-outlined,
.ant-btn-variant-dashed {
  border-color: #d9d9d9;
  color: #121a2d;
  background: #e7e7e7;
}

.ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):active,
.ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):active {
  color: #121a2d;
  border: none;
  background: #e7e7e7;
}

.ant-btn:not(:disabled):focus-visible {
  outline: none;
}

.ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
.ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover {
  color: #121a2d;
  border: none;
  background: #e7e7e7;
}
