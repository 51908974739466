.ScriptTypeGraph__container {
  font-family: inherit;
  width: 450px;
  height: 288px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.ScriptTypeGraph__container h2 {
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  margin: 20px 0 0 0;
  padding: 0;
  line-height: 1;
}

.ScriptTypeGraph__container svg {
  margin-top: -20px;
}

.ScriptTypeGraph__noDataFoundMsg {
  position: absolute;
  font-family: inherit;
  font-style: normal;
  font-size: 14px;
  font-weight: normal;
  color: #909090;
  line-height: 1;
  margin: 0;
  padding: 0;
  top: 132px;
}

.ScriptTypeGraph__noDataFoundMsg--English {
  left: 174px;
}

.ScriptTypeGraph__noDataFoundMsg--Spanish {
  left: 138px;
}

.ScriptTypeGraph__noDataFoundMsg--French {
  left: 140px;
}

.ScriptTypeGraph_VictoryPieContainer h2 {
  position: absolute;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  line-height: 1;
  padding: 0;
  margin: 0;
  top: 16px;
  left: 178px;
}

.ScriptTypeGraph_VictoryPieContainer svg {
  margin: 10px 0 0 0;
}

.ScriptTypeGraph_VictoryLegendContainer {
  position: absolute;
  right: 38px;
  top: 95px;
  width: 70px;
  padding-top: 10px;
  padding-left: 10px;
  height: 100px;
}

.ScriptTypeGraph_VictoryLegendContainer svg {
  width: 300px !important;
}

.ScriptTypeGraph_VictoryLegendContainer tspan {
  padding-bottom: 15px;
}

.ScriptTypeGraph__NoDataMsg {
  font-family: inherit;
  font-size: 14px;
  color: #909090;
  line-height: 1;
  padding: 0;
  margin: 120px 0 0 0;
  text-align: center;
}
