.inventoryTabs {
  width: 100%;
  height: 100%;
}

.ant-tabs {
  height: 100%;
}

.ant-tabs-content-holder {
  display: flex;
}