.PouchesPerHourGraph__container {
  font-family: inherit;
  width: 450px;
  height: 288px;
  box-sizing: border-box;
  overflow: hidden;
}

.PouchesPerHourGraph__container h2 {
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  margin: 20px 0 0 0;
  padding: 0;
  line-height: 1;
}

.PouchesPerHourGraph__container svg {
  margin-top: -38px;
}

.PouchesPerHourGraph__title {
  font-family: inherit;
  font-size: 16px;
}

.PouchesPerHourGraph__legendContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 30px;
  width: 434px;
}

.PouchesPerHourGraph__legendGroup {
  display: flex;
  align-items: center;
  font-family: "Segoe UI";
}

.PouchesPerHourGraph__legendGroup:nth-child(2) {
  padding-left: 15px;
}

.PouchesPerHourGraph__legendGroup p {
  line-height: 1;
  font-size: 13px;
  padding: 0 0 0 4px;
  margin: 0;
  color: #424242;
}

.PouchesPerHourGraph__mainDataLine {
  width: 14px;
  height: 3px;
  background-color: #318ce7;
}

.PouchesPerHourGraph__benchmarkLine {
  width: 14px;
  height: 3px;
  background-color: #e68000;
}

.PouchesPerHourGraph__timeRangeContainer {
  position: absolute;
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 1;
  color: #121a2d;
  bottom: 8px;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
}

@media (max-width: 640px) {
  .PouchesPerHourGraph__timeRangeContainer {
    justify-content: center;
    margin-left: -10px;
  }
}

@media (min-width: 641px) {
  .PouchesPerHourGraph__timeRangeContainer {
    justify-content: space-between;
  }
}

.PouchesPerHourGraph__timeRangeOption {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.PouchesPerHourGraph__timeRangeOption p {
  margin-top: 3px;
}

@media (max-width: 640px) {
  .PouchesPerHourGraph__timeRangeOption:not(:last-child) {
    padding-right: 7px;
  }
}

.PouchesPerHourGraph__squareContainer svg {
  width: 16px;
  fill: transparent;
  stroke: var(--green);
  padding-right: 3px;
}

.PouchesPerHourGraph__squareContainer--active svg {
  fill: var(--green);
}
