.DispensedMedicationsTable__AntStyle
  .ant-pagination
  .ant-pagination-item-active,
.DispensedMedicationsTable__AntStyle
  .ant-pagination
  .ant-pagination-item-active:hover {
  border-color: var(--green) !important;
}
.DispensedMedicationsTable__AntStyle
  .ant-pagination
  .ant-pagination-item-active
  a {
  color: var(--green) !important;
}

.DispensedMedicationsTable__AntStyle
  .ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: var(--green) !important;
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1) !important;
  outline: 0 !important;
}

.DispensedMedicationsTable__AntStyle
  .ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: var(--green) !important;
}

.DispensedMedicationsTable__AntStyle
  .ant-table-wrapper
  .ant-table-column-sorter-up.active,
.DispensedMedicationsTable__AntStyle
  .ant-table-wrapper
  .ant-table-column-sorter-down.active {
  color: var(--green) !important;
}

.DispensedMedicationsTable__AntStyle table {
  padding-left: 14px;
  padding-right: 14px;
  box-sizing: border-box;
}

.DispensedMedicationsTable__AntStyle
  .ant-table-wrapper
  td.ant-table-column-sort {
  background: #fafafa;
}

.DispensedMedicationsTable__AntStyle
  .ant-table-body
  > table
  > tbody
  > tr:nth-child(odd) {
  background: #fafafa;
}

.DispensedMedicationsTable__tableTypeTrays .ant-table-wrapper .ant-table-cell,
.DispensedMedicationsTable__tableTypeTrays
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
.DispensedMedicationsTable__tableTypeTrays
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
.DispensedMedicationsTable__tableTypeTrays
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td,
.DispensedMedicationsTable__tableTypeTrays .ant-table-wrapper tfoot > tr > th,
.DispensedMedicationsTable__tableTypeTrays .ant-table-wrapper tfoot > tr > td {
  position: relative;
  padding: 2px 2px;
  overflow-wrap: break-word;
}

.DispensedMedicationsTable__tableTypeCanisters
  .ant-table-wrapper
  .ant-table-cell,
.DispensedMedicationsTable__tableTypeCanisters
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
.DispensedMedicationsTable__tableTypeCanisters
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
.DispensedMedicationsTable__tableTypeCanisters
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td,
.DispensedMedicationsTable__tableTypeCanisters
  .ant-table-wrapper
  tfoot
  > tr
  > th,
.DispensedMedicationsTable__tableTypeCanisters
  .ant-table-wrapper
  tfoot
  > tr
  > td {
  position: relative;
  padding: 8px 8px;
  overflow-wrap: break-word;
}

.DispensedMedicationsTable__tableTypeTrays
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th:nth-child(4),
.DispensedMedicationsTable__tableTypeTrays
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th:nth-child(5),
.DispensedMedicationsTable__tableTypeTrays
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td:nth-child(4),
.DispensedMedicationsTable__tableTypeTrays
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td:nth-child(5) {
  text-align: right;
}

.DispensedMedicationsTable__tableTypeTrays
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td:nth-child(4),
.DispensedMedicationsTable__tableTypeTrays
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td:nth-child(5) {
  padding-right: 20px;
}

.DispensedMedicationsTable__tableTypeCanisters
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th:nth-child(3),
.DispensedMedicationsTable__tableTypeCanisters
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th:nth-child(4),
.DispensedMedicationsTable__tableTypeCanisters
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th:nth-child(5),
.DispensedMedicationsTable__tableTypeCanisters
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td:nth-child(3),
.DispensedMedicationsTable__tableTypeCanisters
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td:nth-child(4) {
  text-align: right;
}
.DispensedMedicationsTable__tableTypeCanisters
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td:nth-child(4) {
  padding-right: 26px;
}
.DispensedMedicationsTable__tableTypeCanisters
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td:nth-child(5) {
  text-align: right;
}
.DispensedMedicationsTable__tableTypeCanisters
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td:nth-child(5) {
  padding-right: 26px;
}
.DispensedMedicationsTable__tableTypeCanisters
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td:nth-child(3) {
  padding-right: 24px;
}
.DispensedMedicationsTable__tableTypeCanisters
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td:nth-child(4) {
  /* padding-right: 50px; */
}
.DispensedMedicationsTable__tableTypeCanisters
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th:nth-child(4) {
  /* padding-right: 50px; */
}

.DispensedMedicationsTable__AntStyle
  .ant-table-wrapper
  .ant-table-cell:nth-child(odd) {
  background: #fafafa;
}

.DispensedMedicationsTable__AntStyle
  .ant-table-wrapper
  .ant-table-tbody
  > tr:nth-child(even)
  > td {
  background: #ffffff;
}

.DispensedMedicationsTable__AntStyle
  .ant-table-wrapper
  .ant-table-tbody
  > tr:nth-child(odd)
  > td {
  background: #fafafa;
}

.DispensedMedicationsTable__AntStyle .ant-table-wrapper .ant-table-cell {
  background: #fafafa;
}

.DispensedMedicationsTable__AntStyle
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th:nth-child(4) {
  background: #fafafa;
}
