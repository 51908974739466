.PrintLabelsToPDF__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  margin-top: 24px;
  overflow: auto;
}


.PrintLabelsToPDF__stageContainer {
  background-color: lightgray;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PrintLabelsToPDF__stage {
  max-width: 100%;
  max-height: 100%;
}

.PrintLabelsToPDF__buttonsContainer {
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding-top: 24px;
}

.PrintLabelsToPDF__optionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PrintLabelsToPDF__paginationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PrintLabelsToPDF__paginationContainer ul {
  display: flex;
  list-style-type: none;
  width: 100%;
}

.PrintLabelsToPDF__paginationContainer ul li:first-child {
  min-width: 30px;
}

.PrintLabelsToPDF__paginationContainer ul li:last-child {
  min-width: 30px;
}

.PrintLabelsToPDF__paginationContainer header p {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: var(--green);
}

.PrintLabelsToPDF__zoom {
  width: 150px;
}

.PrintLabelsToPDF__zoom label {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: var(--green);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PrintLabelsToPDF__heading {
  margin: 0 0 30px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #454b60;
  width: 100%;
  text-align: center;
}