.ResizableTable__tableContainer {
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  height: 100%;
}

.ResizableTable__multiSiteError {
  color: #721c24;
  background: #fff2f0;
  line-height: 1;
  padding: 14px 10px;
  text-align: center;
  border: 1px solid rgba(245, 198, 203, 0.5);
  border-radius: 0.25rem;
}

.ResizableTable__utilities {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  margin: 0 0 30px 0;
}

.ResizableTable__utilities button {
  height: 24px;
  font-size: 12px;
  cursor: pointer;
}

.ResizableTable__utilities--bottom {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;
  max-height: 53px;
}

.ResizableTable__processDataButton {
  cursor: pointer;
  min-height: 48px;
  min-width: 213px;
  width: max-content;
  padding: 0px 20px;
  outline: none;
  border: none;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  user-select: none;
  margin: 0px;
  background-color: var(--green);
  color: white;
  cursor: pointer;
}

.ResizableTable__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-bottom: 25px;
  border-radius: 6px;
  background: #fff;
  overflow: hidden; /* Clip any scrollbars that appear */
}

/*fixed header background*/
.ResizableTable__wrapper:before {
  content: "";
  position: absolute;
  width: 95%;
  height: 2.4rem;
  display: table;
  z-index: 1;
  background: white;
}

.ResizableTable__table {
  width: 100%;
  display: grid;
  border-collapse: separate;
  margin: 0;
  line-height: 1;
  color: var(--black);
  overflow-x: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ResizableTable__table thead,
.ResizableTable__table tbody,
.ResizableTable__table tr {
  display: contents;
}

.ResizableTable__table th {
  position: relative;
  position: sticky;
  top: -4;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 10px;
  font-size: 18px;
  font-weight: 500;
  z-index: 1;
}

.ResizableTable__table th:last-child {
  visibility: hidden;
}

.ResizableTable__table th div {
  position: absolute;
  margin-top: -0.5rem;
  z-index: 100;
  color: #333;
}

.ResizableTable__table th,
.ResizableTable__table td {
  text-align: left;
  font-size: 14px;
}

.ResizableTable__table tr {
  max-height: 35px;
}

.ResizableTable__table td {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 2px;
  padding-right: 5px;
}

.ResizableTable__table td:not(:last-child) {
  margin-left: 1px;
  margin-right: 1px;
  border: 1px solid #d3d3d3;
}

.ResizableTable__table thead tr th {
  position: sticky;
  top: 0;
}

.ResizableTable__table td:last-child {
  display: flex;
  align-items: center;
}

.ResizableTable__table input {
  display: block;
  height: 100%;
  min-height: 30px;
  box-sizing: border-box;
  border: none;
  outline: none;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ResizableTable__resizeHandle {
  display: block;
  position: absolute;
  cursor: col-resize;
  width: 7px;
  right: 0;
  top: 0;
  z-index: 1;
  border-right: 2px solid transparent;
}

.ResizableTable__resizeHandle:hover {
  border-color: #ccc;
}

.ResizableTable__resizeHandle--active {
  border-color: var(--green);
}

.ResizableTable__utilitiesButtonsGroup {
  display: flex;
  flex-direction: column;
  width: 183px;
  margin-right: 16px;
}

.ResizableTable__localStorageButtonsGroup {
  display: flex;
  flex-direction: column;
  width: 266px;
  margin-right: 16px;
}

.ResizableTable__localStorageButtonsContainer {
  display: flex;
  justify-content: space-between;
}

.ResizableTable__ExcelButtonsGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 172px;
  margin-right: 30px;
}

.ResizableTable__ExcelButtonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ResizableTable__buttonsHeader {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}

.ResizableTable__buttonsHeader h2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  padding: 0;
  margin: 0;
}

.ResizableTable__createEmptyRowIconContainer {
  position: absolute;
  right: 0;
  bottom: 4px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.ResizableTable__createEmptyRowIconContainer svg {
  width: 20px;
  height: 20px;
}

.ResizableTable__ExcelExportButtonContainer {
  margin-top: -2px;
}

.ResizableTable__uploadCsvFileGroup {
  position: relative;
  margin-top: -2px;
}

.ResizableTable__NameOfCsvFileUploaded {
  position: absolute;
  bottom: -22px;
  font-size: 12px;
  font-weight: 300;
  width: 168px;
  max-width: 168px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  margin: 0;
}
