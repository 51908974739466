.SelectTrayPillsForCanister__tableContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 22px;
  box-sizing: border-box;
  width: 85vw;
}

.SelectTrayPillsForCanisterContainer {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  margin-left: 24px;
  padding: 2px 18px;
  width: 300px;
  height: 360px;
  border: 1px solid #e8ecef;
  filter: drop-shadow(0 5px 14px rgba(120, 146, 141, 0.05));
}

.SelectTrayPillsForCanisterContainer p {
  font-size: 14px;
  padding: 14px 0 0 0;
  margin: 0;
  line-height: 1.2;
}

.SelectTrayPillsForCanisterContainer ul {
  list-style: none;
  padding-left: 0;
  font-size: 12px;
  overflow: auto;
  height: 228px;
}

.SelectTrayPillsForCanister__drugList li {
  padding: 0;
  margin: 0;
  line-height: 1;
  display: flex;
  align-items: center;
}

/* .SelectTrayPillsForCanister__drugList li:not(:last-child) {
      padding-bottom: 4px;
  } */

.SelectTrayPillsForCanister__idSquareContainer {
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.SelectTrayPillsForCanister__idSquareContainer svg {
  width: 16px;
  fill: transparent;
  stroke: var(--green);
}

.SelectTrayPillsForCanister__idSquareContainer--active svg {
  fill: var(--green);
}

.SelectTrayPillsForCanister__buttonGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  padding-bottom: 16px;
}

.SelectTrayPillsForCanister__buttonGroup div:first-child {
  margin-right: 24px;
}

.SelectTrayPillsForCanister__buttonGroup > * {
  cursor: pointer;
}

.SelectTrayPillsForCanister__newTrayFillDailyAverageMessage {
  text-align: center;
  font-size: 14px;
  padding: 10px 0;
  margin: 4px 0 0 0;
  line-height: 1;
  width: 640px;
  /* background-color: rgba(8, 155, 171, 0.06);
  filter: drop-shadow(0 5px 14px rgba(120, 146, 141, 0.05));
  border-radius: 6px; */
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 0.25rem;
}

.SelectTrayPillsForCanister__newTrayFillDailyAverage {
  font-weight: 700;
}

.SelectTrayPillsForCanister__pageTitleContainer {
  display: flex;
  align-items: center;
  margin-top: 0;
}

.SelectTrayPillsForCanister__pageTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  font-size: 24px;
  line-height: 33px;
  color: #454b60;
}

.SelectTrayPillsForCanister__backButton {
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.SelectTrayPillsForCanister__tableTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1;
  font-family: "Poppins", sans-serif;
  padding: 10px 0 20px 0;
  margin: 0;
}

.SelectTrayPillsForCanister__tableTitleMain,
.SelectTrayPillsForCanister__tableTitleSub {
  line-height: 1;
  font-family: "Poppins", sans-serif;
  margin: 0;
  text-align: center;
  margin: 0;
}

.SelectTrayPillsForCanister__tableTitleMain {
  font-size: 18px;
  font-weight: 500;
  padding: 0 0 4px 0;
}

.SelectTrayPillsForCanister__tableTitleSub {
  font-size: 12px;
  font-weight: 400;
  padding: 0;
}
