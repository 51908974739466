.ChangePatientLocation__table {
  width: 1%;
  white-space: nowrap;  
}

.ChangePatientLocation__label {
  font-size: 14px;
  color: #089bab;
  padding: 0;
  line-height: 1;  
}

.ChangePatientLocation__value {
  margin-left: 4px;
  color: #121a2d;
  padding-left: 10px;
}

.ChangePatientLocation__current {
  font-size: 16px;
  color: #089bab;
  padding: 0;
  margin: 0;
  line-height: 1;  
}

@media (max-width: 640px) {
  .ChangePatientLocation__overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

@media (min-width: 641px) {
  .ChangePatientLocation__overlay {
    position: absolute;
    min-height: 100%;
    top: 0;
    right: 0;
    background-color: rgba(152, 169, 188, 0.3);
    backdrop-filter: blur(46.2108px);
    z-index: 210;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .ChangePatientLocation__overlay {
    left: -112px;
    position: absolute;
    min-height: 810px;
    height: 100%;
    top: 0;
    right: 0;
  }
}

@media (min-width: 1008px) {
  .ChangePatientLocation__overlay {
    left: -120px; /* Needed to cover vertical menu */
  }
}

.ChangePatientLocation__view {
  display: flex;
  position: relative;
  flex-direction: column;
}

@media (max-width: 640px) {
  .ChangePatientLocation__view {
    height: 100%;
    color: #fff;
    margin: 12px 12px 0 12px;
  }
}

@media (min-width: 641px) {
  .ChangePatientLocation__view {
    margin: 100px auto;
    max-height: 1080px;
    padding-right: 20px;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .ChangePatientLocation__view {
    margin-top: 40px;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    box-sizing: border-box;
  }
}

@media (min-width: 1008px) {
  .ChangePatientLocation__view {
    max-width: 1100px;
    padding-left: 64px;
    padding-right: 64px;
  }
}

.ChangePatientLocation__container {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  font-family: "Poppins", sans-serif;
  color: #121a2d;
}

@media (max-width: 640px) {
  .ChangePatientLocation__container {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .ChangePatientLocation__container {
    width: 100%;
    max-width: 1000px;
    min-height: 560px;
    height: 100%;
    margin-top: 20px;
  }
}

@media (min-width: 1008px) {
  .ChangePatientLocation__container {
    width: 1000px;
    min-height: 700px;
    height: 100%;
    margin-left: 66px;
    margin-right: 66px;
    margin-top: 64px;
  }
}

.ChangePatientLocation__formCloseContainer {
  position: absolute;
  top: 35px;
  right: 36px;
}

.ChangePatientLocation__formCloseContainer:hover {
  cursor: pointer;
}

@media (max-width: 640px) {
  .ChangePatientLocation__header {
    margin-left: 18px;
    margin-top: 1px;
    margin-bottom: 24px;
  }
}

@media (min-width: 641px) {
  .ChangePatientLocation__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    line-height: 1;
  }
}

@media (max-width: 640px) {
  .ChangePatientLocation__backArrow {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 640px) {
  .ChangePatientLocation__goBackTitle {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: rgba(18, 26, 45, 0.5);
    line-height: 1;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 640px) {
  .ChangePatientLocation__backArrowIcon {
    padding-right: 4px;
  }
}

.ChangePatientLocation__title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #121a2d;
}

@media (max-width: 640px) {
  .ChangePatientLocation__title {
    font-size: 20px;
    line-height: 1;
    margin: 24px 0 0 2px;
    padding: 0;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .ChangePatientLocation__title {
    margin: 0 0 30px 0;
    font-size: 30px;
    line-height: 1;
  }
}

@media (min-width: 1008px) {
  .ChangePatientLocation__title {
    font-size: 34px;
    line-height: 27px;
    margin: 0;
  }
}

.ChangePatientLocation__productSelectionContainer {
  display: flex;
  flex-direction: column;
}

@media (max-width: 640px) {
  .ChangePatientLocation__productSelectionContainer {
    margin-top: 10px;
  }
}

@media (min-width: 641px) {
  .ChangePatientLocation__productSelectionContainer {
    margin-top: 40px;
  }
}

.ChangePatientLocation__footer {
  display: flex;
  flex: 1;
  align-items: flex-end;
}

@media (max-width: 640px) {
  .ChangePatientLocation__footer {
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 30px;
  }
}

@media (min-width: 641px) {
  .ChangePatientLocation__footer {
    justify-content: flex-end;
    margin-top: 38px;
    margin-left: 50px;
    margin-right: 42px;
    margin-bottom: 51px;
  }
}

.ChangePatientLocation__quantityInputSection {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.ChangePatientLocation__quantityInputContainer {
  margin-top: 8px;
}

@media (max-width: 640px) {
  .ChangePatientLocation__quantityInputContainer {
    width: inherit;
    max-width: 400px;
  }
}

@media (min-width: 641px) {
  .ChangePatientLocation__quantityInputContainer {
    width: 440px;
  }
}


.ChangePatientLocation__dateInputs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
}

.ChangePatientLocation__errorBannerContainer {
  margin-top: 20px;
}
