.ant-button-000 button {
  font-family: "Poppins", sans-serif;
}

.ant-button-000 .ant-btn-default {
  color: var(--black) !important;
  background-color: #f6f6f6 !important;
  border: 1px solid #d8d8d8 !important;
  border-radius: 3px !important;
}

.ant-button-000 .ant-btn-default:active,
.ant-button-000 .ant-btn-default:hover {
  background-color: #e9e9e9 !important;
}

.ant-button-001 button {
  font-family: "Poppins", sans-serif;
}

.ant-button-001 .ant-btn-default,
.ant-button-001 .ant-btn-default {
  color: white !important;
  background-color: var(--green) !important;
  border: none !important;
  border-radius: 10px !important;
  min-width: 213px !important;
  min-height: 48px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  outline: none !important;
  line-height: 21px !important;
  padding: 0px 20px !important;
  text-align: center !important;
}

.ant-button-001--disabled .ant-btn-default,
.ant-button-001--disabled .ant-btn-default {
  background-color: var(--greenDisabled) !important;
}

.ant-button-001 .ant-btn-default:active,
.ant-button-001 .ant-btn-default:hover {
  background-color: #078b99 !important;
}

.ant-button-001--disabled .ant-btn-default:active,
.ant-button-001--disabled .ant-btn-default:hover {
  background-color: var(--greenDisabled) !important;
}

/* ---------------------------------------------------------------- */

.ant-button-001 button {
  font-family: "Poppins", sans-serif;
}

.ant-button-001 .ant-btn-default,
.ant-button-001 .ant-btn-default {
  color: white !important;
  background-color: var(--green) !important;
  border: none !important;
  border-radius: 10px !important;
  min-width: 213px !important;
  min-height: 48px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  outline: none !important;
  line-height: 21px !important;
  padding: 0px 20px !important;
  text-align: center !important;
}

.ant-button-001--disabled .ant-btn-default,
.ant-button-001--disabled .ant-btn-default {
  background-color: var(--greenDisabled) !important;
}

.ant-button-001 .ant-btn-default:active,
.ant-button-001 .ant-btn-default:hover {
  background-color: #078b99 !important;
}

.ant-button-001--disabled .ant-btn-default:active,
.ant-button-001--disabled .ant-btn-default:hover {
  background-color: var(--greenDisabled) !important;
}

/* ---------------------------------------------------------------- */
.ant-button-003 .ant-btn-default,
.ant-button-003 .ant-btn-default {
  color: var(--black) !important;
  background-color: var(--gray) !important;
  border: none !important;
  border-radius: 6px !important;
  width: 126px !important;
  height: 38px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  outline: none !important;
  line-height: 21px !important;
  padding: 0px 20px !important;
  text-align: center !important;
}

.ant-button-003 .ant-btn-default:active,
.ant-button-003 .ant-btn-default:hover {
  background-color: #cfcfd0 !important;
}

/* ---------------------------------------------------------------- */

.ant-button-004 .ant-btn-default,
.ant-button-004 .ant-btn-default {
  color: white !important;
  background-color: var(--green) !important;
  border: none !important;
  border-radius: 6px !important;
  width: 160px !important;
  height: 38px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  outline: none !important;
  line-height: 21px !important;
  padding: 0px 20px !important;
  text-align: center !important;
}

.ant-button-004--disabled .ant-btn-default,
.ant-button-004--disabled .ant-btn-default {
  background-color: var(--greenDisabled) !important;
}

.ant-button-004 .ant-btn-default:active,
.ant-button-004 .ant-btn-default:hover {
  background-color: #078b99 !important;
}



