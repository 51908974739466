.MetricChartView__tableContainer {
  margin-top: 18px;
  border: 1px solid #e8ecef;
  filter: drop-shadow(0 5px 14px rgba(120, 146, 141, 0.05));
  padding: 0 20px;
}

.MetricChartView__bargraphHeadingContainer {
  display: flex;
  align-items: center;
  margin-top: 28px;
  margin-left: 40px;
  width: 600px;
}

.MetricChartView__dailyAverageContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 30%;
  box-sizing: border-box;
}

.MetricChartView__dailyAverageContainer h3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  padding: 0;
  margin: 0;
}

.MetricChartView__dailyAverageContainer p {
  font-size: 18px;
  line-height: 1;
  padding: 4px 0 0 36px;
  margin: 0;
}

.MetricChartView__bargraphTitle,
.MetricChartView__bargraphTitle .MetricChartView__bargraphTitleMain,
.MetricChartView__bargraphTitle .MetricChartView__bargraphTitleSub {
  line-height: 1;
  margin: 0;
  width: 100%;
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 40%;
  box-sizing: border-box;
}

.MetricChartView__bargraphTitleMain {
  font-weight: 500;
  font-size: 18px;
  padding: 0 0 4px 0;
}

.MetricChartView__bargraphTitleSub {
  font-weight: 400;
  font-size: 12px;
  padding: 0;
}

.MetricChartView__percentageFromLastWeekContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 30%;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  color: #121a2d;
}

.MetricChartView__percentageFromLastWeekContainer p {
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  padding: 0;
  margin: 0 0 0 3px;
}

.MetricChartView__percentageFromLastWeekContainer svg {
  min-width: 24px;
  width: 24px;
}
