.ant-tabs-000 .ant-tabs .ant-tabs-tab-btn:focus-visible,
.ant-tabs-000 .ant-tabs .ant-tabs-tab-remove:focus-visible {
  outline: none !important;
}

.ant-tabs-000 .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--green) !important;
  text-shadow: 0 0 0.25px currentcolor !important;
}

.ant-tabs-000 .ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible),
.ant-tabs-000 .ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible),
.ant-tabs-000 .ant-tabs .ant-tabs-tab-btn:active,
.ant-tabs-000 .ant-tabs .ant-tabs-tab-remove:active {
  color: var(--green);
}

.ant-tabs-000 .ant-tabs .ant-tabs-tab:hover {
    color: var(--greenHover) !important;
}

.ant-tabs-000 .ant-tabs .ant-tabs-tab {
  font-family: "Poppins", sans-serif !important;
  color: var(--black) !important;
}

.ant-tabs-000 .ant-tabs-ink-bar {
  border-bottom: 2px solid var(--green) !important;
  background-color: transparent !important;
}