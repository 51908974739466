.MedicationsDispensed__MetricsChartView--show {
  display: block;
}

.MedicationsDispensed__MetricsChartView--hide {
  display: none;
}

.MedicationsDispensed__medicationsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: "Poppins", sans-serif;
  color: #121a2d;
}

.MedicationsDispensed__SelectTrayPillsForCanisterContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.MedicationsDispensed__pageTitleContainer {
  display: flex;
  align-items: center;
  margin-top: 0;
}

.MedicationsDispensed__pageTitle {
  margin: 0;
  font-size: 24px;
  line-height: 33px;
  color: #454b60;
}

.MedicationsDispensed__backButton {
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.MedicationsDispensed__tabsAndDateSelection {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* align-items: center; */
}

.MedicationsDispensed__DateRangeGroupContainer {
  width: 100%;
  padding-right: 292px;
  border-bottom: 1px solid #e8ecef;
  height: 45px;
}

.MedicationsDispensed__overlay {
  position: fixed;
  z-index: 99;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.MedicationsDispensed__modal {
  position: absolute;
  z-index: 110;
  left: 50%;
  transform: translate(-50%, 0%);
  box-sizing: border-box;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.MedicationsDispensed__dateRangeGroup {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.MedicationsDispensed__dateRangeLabel {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #089bab;
}

.MedicationsDispensed__dateRange {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  color: #121a2d;
  cursor: pointer;
}

.MedicationsDispensed__buttonGroup {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 304px;
  min-width: 304px;
}

.MedicationsDispensed__validDateRangeAndButtonContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.MedicationsDispensed__invalidDateRangeMessage {
  position: absolute;
  top: -30px;
  font-size: 14px;
  line-height: 1;
  padding: 0;
  margin: 0;
  color: #dc3545;
  font-weight: 500;
}

.MedicationsDispensed__showApplicableDates {
  text-align: center;
  font-size: 14px;
  padding: 6px 6px;
  margin: 18px 0 0 0;
  line-height: 1;
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 0.25rem;
  font-size: 12px;
}
