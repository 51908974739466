.dispensingOrderQueueHeaderIcons {
  display: flex;
  align-items: center;
}

.dispensingOrderQueueHeaderIcon {
  margin: 0 0 0 10px;
}

.dispensingOrderQueueTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  margin: 0;
  color: #252631;
}

@media (max-width: 640px) {
  .dispensingOrderQueue {
    width: 100%;
  }

  .dispensingOrderQueueHeader {
    display: none;
  }

  .facilityColumn {
    display: flex;
    flex-direction: column;
    /* vertical scroll */
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
    width: 100%;
    height: 100%;
    max-height: 100%;
    margin-top: 16px;
    overflow: auto;
  }

  .facilityColumn::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 641px) {
  .dispensingOrderQueueTitle {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .modal {
    background-color: transparent;
    z-index: 25;
  }

  .dispensingOrderQueue {
    display: flex;
    flex-direction: column;
    height: 100%;
    user-select: none;
    margin-right: 20px;
    border: 1px solid #eef3f4;
    box-shadow: 0 3px 10px rgba(120, 146, 141, 0.12);
    box-sizing: border-box;
    border-radius: 16px;
  }

  @media (max-width: 1007px) {
    .dispensingOrderQueue {
      width: 360px;
      min-width: 360px;
    }
  }

  @media (min-width: 1008px) {
    .dispensingOrderQueue {
      width: 360px;
      min-width: 360px;
    }
  }

  .dispensingOrderQueueHeader {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f2f4f6;
    box-sizing: border-box;
    padding: 20px;
  }
  
  .titleAndFilter {
    display: flex;
    align-items: center;
  }
  
  .loaderAndHeaderGroup {
    display: flex;
    align-items: center;
  }
  
  .dispensingOrderQueueTitle {
    margin-right: 10px
  }
  
  .titleAndFilter > :global(.multiselect-container) {
    margin-left: 0;
  }
  
  .titleAndFilter > :global(.multiselect-container .funnel-icon-wrapper) {
    display: flex;
    align-items: center;
  }

  .facilityColumn {
    display: flex;
    flex-direction: column;
    /* vertical scroll */
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }

  .facilityColumn::-webkit-scrollbar {
    display: none;
  }

  .facilityColumn {
    height: 100%;
    position: relative;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
  }

  @media (max-width: 1007px) {
    .facilityColumn {
      padding: 6px;
    }
  }

  @media (min-width: 1008px) {
    .facilityColumn {
      padding: 12px;
    }
  }
}

.refreshIconContainer {
  padding-top: 3px;
  cursor: pointer;
}

.refreshIconContainer svg {
  stroke: var(--black);
  width: 18px;
}

.MultiSelectDropDownContainer {
  margin-top: 28px;
}
