.ant-radioGroup-000 .ant-radio-group {
  display: flex;
  align-items: center;
}

.ant-radioGroup-000 .ant-radio-wrapper span {
  white-space: nowrap;
  font-family: "Poppins", sans-serif;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1;
  color: #121a2d;
}

.ant-radioGroup-000 .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: var(--green);
  background-color: var(--green);
}
