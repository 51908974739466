.scrollArea {
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 10px;
}

@media (min-width: 641px) {
  .scrollArea {
    height: 100%;
  }
}

@media (min-width: 1008px) {
  .scrollArea {
    height: 735px;
  }
}

.Test__list-item {
  margin: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  box-sizing: border-box;
  padding: 5px;
  cursor: pointer;
  background: white;
}

.container {
  background-color: #f8f8ff;
  padding: 60px;
}

.rows__mainGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
}

.row__mainGroup {
  width: 100%;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  margin: 0;
  margin-bottom: 10px;
  border: 1px solid #e8ecef;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  color: #121a2d;
  padding: 0;
}

@media (max-width: 640px) {
  .row__mainGroup {
    font-size: 14px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .row__mainGroup {
    /* height: 48px; */
    min-height: 48px;
    font-size: 13px;
  }
}

@media (min-width: 1008px) {
  .row__mainGroup {
    /* height: 80px; */
    min-height: 80px;
    font-size: 14px;
  }
}

.row__mainGroup--expanded {
  border: 1px solid #089bab;
  padding-bottom: 6px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  margin: 0;
  width: 100%;
  min-height: 50px;
  font-size: 12px;
  font-weight: 300;
  color: rgb(8, 155, 171);
  border-top: 2px solid rgba(217, 225, 236, 0.3);
}

.row--children {
  color: #121a2d;
}

@media (min-width: 640px) and (max-width: 1007px) {
  .row--children {
    font-size: 13px;
  }
}

@media (min-width: 1008px) {
  .row--children {
    font-size: 14px;
  }
}

.productsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  margin: 0;
}

.topRow__name {
  margin-left: 38px;
  min-width: 212px;
  width: 212px;
  margin-right: 30px;
}

.topRow__nickname {
  min-width: 220px;
  width: 220px;
  margin-right: 30px;
}

.topRow__type {
  min-width: 220px;
  width: 220px;
  margin-right: 30px;
}

.topRow__position {
  min-width: 146px;
  width: 146px;
  margin-right: 30px;
}

.topRow__isControlled {
  min-width: 114px;
  width: 114px;
  margin-right: 30px;
}

.topRow__barcode {
  min-width: 154px;
}

.topRow__qoh {
  min-width: 80px;
  text-align: right;
  margin-right: 20px;
}

.topRow__min {
  min-width: 80px;
  text-align: right;
  margin-right: 20px;
}

.topRow__max {
  min-width: 80px;
  text-align: right;
}

.rowTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  background-color: transparent;
  box-shadow: 0px 5px 14px rgba(120 146 141 / 5%);
  width: 100%;
  line-height: 16px;
  font-weight: 300;
  border-radius: 10px;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .rowTop {
    min-height: 48px;
    font-size: 13px;
  }
}

@media (min-width: 1008px) {
  .rowTop {
    min-height: 79px;
    font-size: 14px;
  }
}

.rowTop--expanded {
  background-color: rgba(217, 225, 236, 0.3);
}

.lineBreak {
  flex-basis: 100%;
  height: 0;
}

.fullWidth {
  width: 100%;
}

.subRows__group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

@media (min-width: 640px) and (max-width: 1007px) {
  .subRow__product {
    margin-left: 46px;
    margin-right: 20px;
    min-width: 930px;
  }
}

@media (min-width: 1008px) {
  .subRow__product {
    margin-left: 46px;
    margin-right: 20px;
    min-width: 930px;
  }
}

@media (min-width: 640px) and (max-width: 1007px) {
  .subRow__name {
    margin-left: 6px;
    min-width: 170px;
    width: 170px;
    margin-right: 10px;
  }
}

@media (min-width: 1008px) {
  .subRow__name {
    margin-left: 8px;
    min-width: 212px;
    width: 212px;
    margin-right: 30px;
  }
}

@media (min-width: 640px) and (max-width: 1007px) {
  .subRow__nickname {
    min-width: 176px;
    width: 176px;
    margin-right: 10px;
  }
}

@media (min-width: 1008px) {
  .subRow__nickname {
    min-width: 220px;
    width: 220px;
    margin-right: 30px;
  }
}

@media (min-width: 640px) and (max-width: 1007px) {
  .subRow__type {
    min-width: 176px;
    width: 176px;
    margin-right: 10px;
  }
}

@media (min-width: 1008px) {
  .subRow__type {
    min-width: 220px;
    width: 220px;
    margin-right: 30px;
  }
}

@media (min-width: 640px) and (max-width: 1007px) {
  .subRow__position {
    min-width: 117px;
    width: 117px;
    margin-right: 10px;
  }
}

@media (min-width: 1008px) {
  .subRow__position {
    min-width: 146px;
    width: 146px;
    margin-right: 30px;
  }
}

@media (min-width: 640px) and (max-width: 1007px) {
  .subRow__isControlled {
    min-width: 91px;
    width: 91px;
    margin-right: 10px;
  }
}

@media (min-width: 1008px) {
  .subRow__isControlled {
    min-width: 114px;
    width: 114px;
    margin-right: 30px;
  }
}

@media (min-width: 640px) and (max-width: 1007px) {
  .subRow__barcode {
    min-width: 142px;
    width: 142px;
    margin-right: 10px;
  }
}

@media (min-width: 1008px) {
  .subRow__barcode {
    min-width: 178px;
    width: 178px;
    margin-right: 30px;
  }
}

@media (min-width: 640px) and (max-width: 1007px) {
  .subRow__qoh {
    min-width: 70px;
    width: 70px;
    margin-right: 30px;
  }
}

@media (min-width: 1008px) {
  .subRow__qoh {
    min-width: 56px;
    width: 56px;
    margin-right: 10px;
  }
}

@media (min-width: 640px) and (max-width: 1007px) {
  .subRow__min {
    min-width: 54px;
    width: 54px;
    margin-right: 30px;
  }
}

@media (min-width: 1008px) {
  .subRow__min {
    min-width: 68px;
    width: 68px;
    margin-right: 30px;
  }
}

@media (min-width: 640px) and (max-width: 1007px) {
  .subRow__max {
    min-width: 29px;
    width: 29px;
    margin-right: 30px;
  }
}

@media (min-width: 1008px) {
  .subRow__max {
    min-width: 36px;
    width: 36px;
    margin-right: 30px;
  }
}

.subRow__editIconContainer {
  width: 14px;
  margin-right: 16px;
  padding: 2px;
}

.subRow__editIconContainer:hover {
  cursor: pointer;
  width: 16px;
  transition: all 0.2s ease-in-out;
  margin-right: 14px;
}

.subRow__editIcon {
  /* fill: #0275d8; */
  width: 100%;
  height: auto;
}

.subRow__trashIconContainer {
  width: 14px;
  padding: 2px;
}

.subRow__trashIconContainer:hover {
  cursor: pointer;
  min-width: 16px;
  transition: all 0.2s ease-in-out;
}

.subRow__trashIcon {
  width: 100%;
  height: auto;
  /* transition: all 2s ease-in-out; */
}

.row__hasChildrenIconContainer {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .row__hasChildrenIconContainer {
    margin-left: 6px;
  }
}

@media (min-width: 1008px) {
  .row__hasChildrenIconContainer {
    margin-left: 16px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .row__hasNoChildrenIconContainer {
    margin-left: 23px;
  }
}

@media (min-width: 1008px) {
  .row__hasNoChildrenIconContainer {
    margin-left: 31px;
  }
}

.row__hasChildrenIcon {
  width: 16px;
  height: 16px;
}

.action_items {
  display: flex;
  align-items: center;
  position: relative;
}

.contextMenuContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-right: 0;
  height: 100%;
  min-height: 52px;
  min-width: 68px;
}

.contextMenuContainer:hover {
  cursor: pointer;
}

.contextMenuWrapper {
  position: relative;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .contextMenuWrapper {
    right: 120px;
    top: 15px;
  }
}

@media (min-width: 1008px) {
  .contextMenuWrapper {
    right: 99px;
    top: 10px;
  }
}
