.Products__UtilitiesGroup {
  display: flex;
}

@media (min-width: 641px) {
  .Products__UtilitiesGroup {
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
}

@media (max-width: 640px) {
  .Products__UtilitiesGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.Products__scroll {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
  padding-bottom: 16px;
}

@media (max-width: 640px) {
  .Products__scroll {
    display: flex;
    flex-direction: column;
    flex: 1;
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }
}

@media (max-width: 640px) {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .Products__scroll::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 641px) {
  .Products__scroll {
    padding-right: 10px;
  }
}

.Products__listitem {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  margin: 0;
  margin-bottom: 10px;
  border: 1px solid #e8ecef;
  box-sizing: border-box;
  filter: drop-shadow(0 5px 14px rgba(120, 146, 141, 0.05));
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  color: #121a2d;
  padding: 0;
  list-style: none;
}

.Products__listitem > li,
.Products__listitem_pending > li,
.Products__listitem_disabled > li {
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  color: #121a2d;
}

@media (max-width: 640px) {
  .Products__listitem > li,
  .Products__listitem_pending > li,
  .Products__listitem_disabled > li {
    font-size: 14px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .Products__listitem {
    height: 48px;
    min-height: 48px;
  }
  .Products__listitem > li,
  .Products__listitem_pending > li,
  .Products__listitem_disabled > li {
    font-size: 13px;
  }
}

@media (min-width: 1008px) {
  .Products__listitem {
    min-height: 80px;
    height: 80px;
  }
  .Products__listitem > li,
  .Products__listitem_pending > li,
  .Products__listitem_disabled > li {
    font-size: 14px;
  }
}

.Products__listitem--pending {
  background-color: #eed202;
}

.Products__listitem--disabled {
  background-color: lightgrey;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .Products__listitem,
  .Products__listitem_pending,
  .Products__listitem-disabled {
    height: 48px;
    min-height: 48px;
  }
}

.Products__actionsHeading {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 29px;
}

.Products__actionsContainer {
  display: flex;
  justify-content: flex-end;
  flex: 1 1;
  text-align: right;
  position: relative;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .Products__actionsContainer {
    margin-right: 14px;
  }
}

@media (min-width: 1008px) {
  .Products__actionsContainer {
    margin-right: 30px;
  }
}

.Products__list li {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Products__list .Products__actionsContainer {
  overflow: visible;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .Products__list li:nth-child(1) {
    /* Id */
    min-width: 80px;
    width: 80px;
    margin-left: 20px;
    margin-right: 10px;
  }

  .Products__list li:nth-child(2) {
    /* description */
    min-width: 210px;
    width: 210px;
    margin-right: 10px;
  }

  .Products__list li:nth-child(3) {
    /* form */
    min-width: 120px;
    width: 120px;
    margin-right: 10px;
  }

  .Products__list li:nth-child(4) {
    /* manufacturer */
    min-width: 130px;
    width: 130px;
    margin-right: 10px;
  }

  .Products__list li:nth-child(5) {
    /* label id */
    min-width: 116px;
    width: 116px;
    margin-right: 10px;
  }

  .Products__list li:nth-child(6) {
    /* category */
    min-width: 110px;
    width: 110px;
    margin-right: 10px;
  }

  .Products__list li:nth-child(7) {
    /* actions */
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

@media (min-width: 1008px) {
  .Products__list li:nth-child(1) {
    /* Id */
    min-width: 130px;
    width: 130px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .Products__list li:nth-child(2) {
    /* description */
    min-width: 310px;
    width: 310px;
    margin-right: 20px;
  }

  .Products__list li:nth-child(3) {
    /* form */
    min-width: 180px;
    width: 180px;
    margin-right: 20px;
  }

  .Products__list li:nth-child(4) {
    /* manufacturer */
    min-width: 230px;
    width: 230px;
    margin-right: 20px;
  }

  .Products__list li:nth-child(5) {
    /* label id */
    min-width: 130px;
    width: 130px;
    margin-right: 20px;
  }

  .Products__list li:nth-child(6) {
    /* category */
    min-width: 130px;
    width: 130px;
    margin-right: 20px;
  }
}

.Products__header {
  padding-left: 0;
  list-style: none;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #121a2d;
  margin-left: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
}

@media (max-width: 640px) {
  .Products__header {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .Products__header {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    border-bottom: 2px solid #089bab;
    margin-left: 1px;
    padding-bottom: 13px;
    font-size: 13px;
  }
}

@media (min-width: 1008px) {
  .Products__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 2px solid #089bab;
    padding-bottom: 14px;
    font-size: 14px;
  }
}

@media (max-width: 640px) {
  .Products__actions {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (max-width: 640px) {
  .Products__actionsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    position: relative;
    height: 30px;
  }
}

@media (max-width: 640px) {
  .Products__createButtonContainer {
    margin-left: 24px;
    margin-right: 24px;
  }
}

@media (min-width: 641px) {
  .Products__createButtonContainer {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 640px) {
  .Products__searchBarContainer {
    position: absolute;
    top: 0;
    width: 78%;
    padding-left: 32px;
    box-sizing: border-box;
    z-index: 5;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .Products__searchBarContainer {
    flex-grow: 1;
    margin-right: 20px;
  }
}

.Products__popupContainer {
  position: relative;
}

@media (min-width: 1008px) {
  .Products__searchBarContainer {
    width: 100%;
    display: flex;
    gap: 20px;
    align-items: center;
  }
}

.Products__pendingApprovalContainer {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
}

.Products__actionsContainer:hover {
  cursor: pointer;
}

.Products__actionsIconContainer {
  display: flex;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .Products__popupContainer {
    top: var(--popupTopMd);
    right: var(--popupRightMd);
  }
}

@media (min-width: 1008px) {
  .Products__popupContainer {
    top: var(--popupTopLg);
    right: var(--popupRightLg);
  }
}

@media only screen and (max-width: 640px) {
  .Products__errorBannerContainer {
    margin: 0 10px 10px 10px;
    position: relative;
    z-index: 210;
  }
}

@media only screen and (min-width: 641px) {
  .Products__errorBannerContainer {
    width: 100%;
    margin-bottom: 12px;
    z-index: 250;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1007px) {
  .Products__errorBannerContainer {
    margin-top: 35px;
  }
}

@media only screen and (min-width: 1008px) {
  .Products__errorBannerContainer {
    margin-top: 70px;
  }
}
