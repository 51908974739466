.BarGraph1__container {
  margin-top: 0;
  width: 450px;
  height: 288px;
  box-sizing: border-box;
  overflow: hidden;
}

.BarGraph1__container h2 {
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  margin: 20px 0 0 0;
  padding: 0;
  line-height: 1;
}

.BarGraph1__container svg {
  margin-top: -24px;
}

.BarGraph__container {
  margin-top: 0;
  width: 650px;
  box-sizing: border-box;
}

.BarGraph__container h2 {
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  margin: 20px 0 0 0;
  padding: 0;
  line-height: 1;
}

.BarGraph__container svg {
  margin-top: -24px;
}
