.BarLineGraph__container {
  font-family: inherit;
  width: 450px;
  height: 288px;
  box-sizing: border-box;
  overflow: hidden;
  padding-left: 8px;
}

.BarLineGraph__container h2 {
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  margin: 20px 0 0 0;
  padding: 0;
  line-height: 1;
}

.BarLineGraph__container svg {
  margin-top: -30px;
}

.BarLineGraph__legendContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  width: 434px;
}

.BarLineGraph__legendGroup {
  display: flex;
  align-items: center;
  font-family: "Segoe UI";
}

.BarLineGraph__legendGroup:nth-child(2) {
  padding-left: 15px;
}

.BarLineGraph__legendGroup p {
  line-height: 1;
  font-size: 13px;
  padding: 0 0 0 4px;
  margin: 0;
  color: #424242;
}

.BarLineGraph__legendGroup svg {
  width: 14px;
  stroke: #f28c28;
  fill: #f28c28;
  margin: 0;
}

.BarLineGraph__line {
  width: 14px;
  height: 3px;
  background-color: #318ce7;
}
