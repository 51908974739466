.TableContent__actionsIconsContainer {
  display: flex;
  align-items: center;
}

.TableContent__actionIconContainer {
  display: flex;
  align-items: center;
  padding-left: 8px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.TableContent__actionIconContainer svg {
  width: inherit;
  height: inherit;
}

.TableContent__actionIconContainer--large {
  width: 28px;
  height: 28px;
}

.TableContent__actionIconContainer--large svg {
  width: inherit;
  height: inherit;
}

.TableContent__actionIconContainer--small {
  width: 22px;
  height: 22px;
}

.TableContent__actionIconContainer--small svg {
  width: inherit;
  height: inherit;
}

.TableContent__deleteRowIcon {
  fill: #dc3545;
}

.TableContent__invalidFieldEntry {
  padding: 2px;
  border: 1px solid #dc3545;
}

.TableContent__selectContainer .ant-select-item-option-selected {
  background-color: var(--green) !important;
}

.TableContent__inputContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.TableContent__searchIconContainer {
  background: #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  cursor: pointer;
  margin-top: 1px;
  border-radius: 4px;
}
.TableContent__searchIconContainer svg {
  margin-top: 2px;
  color: rgba(0, 0, 0, 0.45);
}
