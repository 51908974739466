.ant-selectGroup-000
  .ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: var(--green);
}

.ant-selectGroup-000
  .ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: var(--green);
}

.ant-selectGroup-000 .ant-select-selector {
  box-sizing: border-box;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 5px !important;
}

/* .ant-select-item-option-selected {
  background-color: var(--green) !important;
} */

.ant-selectGroup-000-error
  .ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: var(--green);
}

.ant-selectGroup-000-error
  .ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: var(--green);
}

.ant-selectGroup-000-error .ant-select-selector {
  box-sizing: border-box;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 5px !important;
}

.ant-selectGroup-000-error
  .ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  background: #fff2f0 !important;
}

.ant-selectGroup-001 .ant-select .ant-select-arrow {
  font-size: 18px;
  color: var(--black);
}

.ant-selectGroup-001
  :where(.css-dev-only-do-not-override-1htoz2s).ant-select
  .ant-select-selection-item {
  font-size: 18px;
}

.ant-selectGroup-001
  .ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-color: #9cd7dd;
}

.ant-selectGroup-001
  .ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: #9cd7dd;
}

.ant-selectGroup-001
  .ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #9cd7dd;
}

.ant-selectGroup-001 .ant-select-selector {
  box-sizing: border-box;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 5px !important;
}

.ant-selectGroup-001 .ant-select .ant-select-selection-wrap {
  padding-bottom: 10px;
}

.ant-selectGroup-001 .ant-select .ant-select-arrow {
  font-size: 18px;
  color: var(--black);
  padding-bottom: 10px;
}

.ant-selectGroup-002 .ant-select .ant-select-arrow {
  font-size: 18px;
  color: var(--black);
  padding-bottom: 10px;
}

.ant-selectGroup-002 .ant-select .ant-select-selection-item {
  font-size: 18px;
}

/* .ant-selectGroup-002-mobile */
.ant-select-dropdown .ant-select-item-option,
.ant-select-item-option-content {
  font-size: 18px;
}
.ant-selectGroup-002
  .ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-color: rgba(0, 0, 0, 0.42);
}

.ant-selectGroup-002
  .ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: rgba(0, 0, 0, 0.42);
}

.ant-selectGroup-002
  .ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: rgba(0, 0, 0, 0.42);
}

.ant-selectGroup-002 .ant-select-selector {
  box-sizing: border-box;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 0 !important;
}

.ant-selectGroup-002 .ant-select .ant-select-selection-wrap {
  padding-bottom: 10px;
}

.ant-selectGroup-002
  .ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  box-shadow: none;
}

.ant-selectGroup-002
  .ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: var(--green);
  background-color: #ffffff;
}

.ant-selectGroup-002
  .ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  color: var(--green);
  background-color: #ffffff;
}

.ant-selectGroup-002-mobile .ant-select .ant-select-arrow {
  font-size: 14px;
  color: var(--black);
  padding-bottom: 10px;
}

.ant-selectGroup-002-mobile .ant-select .ant-select-selection-item {
  font-size: 14px;
}

/* .ant-selectGroup-002-mobile */
/* .ant-select-dropdown .ant-select-item-option,
.ant-select-item-option-content {
  font-size: 14px;
} */

.ant-selectGroup-002-mobile
  .ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-color: rgba(0, 0, 0, 0.42);
}

.ant-selectGroup-002-mobile
  .ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: rgba(0, 0, 0, 0.42);
}

.ant-selectGroup-002-mobile
  .ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.42);
}

.ant-selectGroup-002-mobile .ant-select-selector {
  box-sizing: border-box;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 0 !important;
}

.ant-selectGroup-002-mobile .ant-select .ant-select-selection-wrap {
  padding-bottom: 10px;
}

.ant-selectGroup-002-mobile
  .ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  color: var(--green);
  background-color: #ffffff;
}

.ant-selectGroup-002-mobile
  .ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: var(--green);
  background-color: #ffffff !important;
}
