.tabBar__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 640px) {
  .tabBar__container {
    box-sizing: border-box;
    min-height: 26px;
    width: 100%;
    display: flex;
    background-color: #ffffff;
    z-index: 2;
    justify-content: flex-start;
    position: relative;
    left: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 24px;
    overflow-x: scroll;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .tabBar__container::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 641px) {
  .tabBar__container {
    width: 100%;
    border-bottom: 1px solid lightgray;
    justify-content: flex-start;
  }
}

@media (min-width: 1008) {
  .tabBar__container {
    margin-bottom: 20px;
  }
}

.tabBar__itemContainer:not(:last-child) {
  padding-right: 34px;
}

@media (min-width: 641px) and (max-width: 700px) {
  .tabBar__itemContainer:not(:last-child) {
    padding-right: 30px;
  }
}
