.Report2Calcs__pageContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.Report2Calcs__mainContainer {
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 40px;
}

.Report2Calcs__buttonGroup {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 95%;
  margin-bottom: auto;
}

.Report2Calcs__cardsGroup {
  display: flex;
  justify-content: center;
  position: relative;
}

.Report2Calcs__card {
  width: 500px;
  min-height: 80px;
  border: 1px solid #e8ecef;
  box-shadow: 0 5px 14px rgb(120 146 141 / 5%);
  border-radius: 2px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 6px;
  box-sizing: border-box;
}

.Report2Calcs__card:not(:last-child) {
  border-bottom: 1px dotted var(--blackLight);
}

.Report2Calcs__cardFieldCollectionAndName {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #089bab;
}

.Report2Calcs__operationsCard {
  position: absolute;
  left: 254px;
  z-index: 1;
  min-width: 174px;
  margin-right: 10px;
  background: #ffffff;
  box-shadow: 0 2px 16px rgb(153 155 168 / 34%);
  border-radius: 10px;
  box-sizing: border-box;
}

.Report2Calcs__operationsCard--hide {
  display: none;
}
.Report2Calcs__contentRow {
  text-align: left;
  font-size: 12px;
  line-height: 1;
  padding: 6px;
  cursor: crosshair;
  transition: all ease-in-out 0.2s;
}

.Report2Calcs__contentRow--header {
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  color: var(--green);
  padding-bottom: 12px;
  margin-bottom: 0;
  cursor: default;
}

.Report2Calcs__contentRow:active,
.Report2Calcs__contentRow:hover {
  background-color: var(--gray);
}

.Report2Calcs__contentRow--header:active,
.Report2Calcs__contentRow--header:hover {
  background-color: transparent;
}

.Report2Calcs__contentRow:not(:last-child) {
  border-bottom: 1px solid var(--blackLight);
}

.Report2Calcs__CardContentTitleGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
}

.Report2Calcs__CardContentTitleGroup--hasIcon {
  padding-top: 8px;
  /* padding-bottom: 8px; */
}

.Report2Calcs__CardContentTitleGroup--noIcon {
  padding-top: 16px;
}

.Report2Calcs__CardFieldQueriesContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 35px;
  min-height: 35px;
  background: #ffffff;
  border: 1px solid #e8ecef;
  box-shadow: 0 5px 14px rgb(120 146 141 / 5%);
  border-radius: 2px;
  box-sizing: border-box;
  padding: 10px 28px 10px 26px;
  margin: 4px 0 6px 0;
  cursor: pointer;
}

.Report2Calcs__CardFieldQueriesInputGroup {
  display: flex;
  align-items: center;
  width: 100%;
}

.Report2Calcs__CardFieldQueries {
  font-size: 16px;
  color: var(--black);
  line-height: 1;
  margin: 0;
  padding: 8px 0;
  width: 26px;
}

.Report2Calcs__CardFieldQueries--boolean {
  width: 56px;
}

.Report2Calcs__input {
  height: 20px;
  border: none;
  background-color: var(--blackExtraLight);
  outline: none;
  margin-left: 4px;
}

.Report2Calcs__input--string {
  width: 350px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  box-sizing: border-box;
}

.Report2Calcs__input--number {
  width: 95px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  box-sizing: border-box;
}

.Report2Calcs__trashIconContainer {
  margin-left: auto;
}

.Report2Calcs__trashIconContainer > svg {
  width: 14px;
  height: 14px;
  cursor: pointer;
  fill: rgb(8, 155, 171);
  padding: 0;
  margin: 0;
}

.Report2Calcs__headerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  margin-bottom: 14px;
}

.Report2Calcs__header {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #454b60;
  padding-bottom: 24px;
}

.Report2Calcs__includesSVG {
  width: 10px;
  height: 10px;
}

.Report2Calcs__notIncludesSVG {
  width: 12px;
  height: 12px;
}

.Report2Calcs__contentRow svg {
  display: inline;
}

.Report2Calcs__clickDateMsg {
  font-size: 12px;
  color: var(--blackLight);
}

.Report2Calcs__toggleSwitchesContainer {
  display: flex;
  /* margin-bottom: 5px; */
}

.Report2Calcs__toggleSwitchGroup {
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}

.report2calcs__toggleswitchgroup:not(last-child) {
  margin-bottom: 10px;
}

.Report2Calcs__toggleSwitchLabel {
  font-size: 12px;
  color: var(--black);
  margin: 0 10px 0 0;
  padding: 0;
  line-height: 1;
}

.Report2Calcs__createButtonContainer {
  margin-right: -5px;
}

.Report2Calcs__popupGroup {
  display: flex;
  justify-content: flex-start;
}

.Report2Calcs__queryWarning {
  height: 8px;
  width: 8px;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  background-color: var(--orange);
}

.Report2Calcs__queryWarning--none {
  background-color: transparent;
}

.Report2Calcs__previewButtonContainer {
  margin-bottom: 10px;
}
