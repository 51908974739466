/* Mobile View Styling */
@media only screen and (max-width: 640px) {
  .Login__view {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #ffffff;
  }

  .Login__header {
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    width: 100%;
    height: 30%;
    overflow: hidden;
  }

  .Login__header > img {
    width: 100%;
  }

  .Login__logo {
    position: absolute;
    display: flex;
    align-items: center;
  }

  .Login__logo > p {
    font-style: normal;
    font-weight: 300;
    font-size: 38px;
    line-height: 57px;
    letter-spacing: 0.5px;
    color: #778ca2;
  }

  .Login__logo > p > b {
    font-weight: 600;
  }

  .Login__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    flex: 1;
  }

  .Login__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 20px;
  }

  .Login__footer > button {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    background-color: #089bab;
    color: white;
    border-radius: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    width: 100%;
    height: 48px;
  }

  .Login__footer > button:hover {
    cursor: pointer;
  }

  .Login__body > h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 42px;
    letter-spacing: 0.7px;
    color: #089bab;
  }
}

.Login__form {
  width: 100%;
}
