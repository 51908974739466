.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100vw;
  height: 100vh;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  /*width: 100%;*/
}

.scrollable {
  overflow: auto;
}

.w-100 {
  width: 100% !important;
}

.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}

.w-70 {
  width: 70% !important;
}

.w-60 {
  width: 60% !important;
}

.w-50 {
  width: 50% !important;
}

.w-40 {
  width: 40% !important;
}

.w-30 {
  width: 30% !important;
}

.w-20 {
  width: 20% !important;
}

.w-10 {
  width: 10% !important;
}

.w-5 {
  width: 5% !important;
}

.flexEnd {
  justify-content: flex-end;
}

/* FORMS */
.formView {
  display: flex;
  flex-direction: column;
  margin: auto 0;
  width: 1000px;
  height: 739px;
  background-color: white;
  border: 1px solid white;
  border-radius: 20px;
}

.formHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  margin-left: 50px;
  margin-right: 20px;
}

.formCloseContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 60%;
}

.formCloseContainer > img {
  cursor: pointer;
}

.formBody {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  margin-right: 50px;
}

.formRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.formColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.formFooter {
  display: flex;
  align-items: flex-end;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
  flex: 1;
}

.controlGroup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: 75px;
  margin: 6px 0 0 0;
  position: relative;
}

@media (max-width: 640px) {
  .controlGroup {
    margin: 0;
  }
}

.controlBorder {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid var(--black);
}

.controlLabel {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: var(--green);
}

.controlInput {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: var(--black);
  border: none;
  outline: none;
  flex: 1;
  flex-direction: row;
  max-height: 36px;
  min-width: 50px;
  background: transparent;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .controlGroup {
    width: 100%;
  }
}

@media (max-width: 641px) {
  .controlInput {
    font-size: 14px;
    line-height: 1;
    max-height: 30px;
  }
}

.controlValidationLabel {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  color: var(--red);
}

.controlButtonInputGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  width: 100%;
}

.controlButton {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.controlButtonPopup {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 100%;
  max-height: 300px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  border: solid 2px var(--gray);
  background-color: #ffffff;
  overflow: auto;
  margin-top: 10px;
  border-radius: 6px;
  width: 100%;
}

.controlButtonPopupListItem {
  position: absolute;
  left: 0;
  display: flex;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: var(--black);
  cursor: pointer;
  margin: 0;
  padding: 5px 10px;
  border: 1px solid red;
}

.controlButtonPopupListItem:hover {
  /*background-color: var(--gray);*/
  color: var(--green);
}

.button {
  cursor: pointer;
  min-height: 48px;
  padding: 0 50px;
  outline: none;
  border: none;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
}

.primaryButton {
  background-color: var(--green);
  color: white;
}

.secondaryButton {
  background-color: var(--gray);
  color: black;
}

.switch {
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.switch i {
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  width: 46px;
  height: 26px;
  background-color: #d9e1ec;
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;
}

.switch i::before {
  content: "";
  position: absolute;
  left: 0;
  width: 42px;
  height: 22px;
  background-color: #d9e1ec;
  border-radius: 11px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.25s linear;
}

.switch i::after {
  content: "";
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.0510643), 0 1px 1px rgba(0, 0, 0, 0.1),
    0 2px 2px rgba(0, 0, 0, 0.05);
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;
}

.switch:active i::after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}

.switch:active input:checked + i::after {
  transform: translate3d(16px, 2px, 0);
}

.switch input {
  display: none;
}

.switch input:checked + i {
  background-color: var(--green);
}

.switch input:checked + i::before {
  transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}

.switch input:checked + i::after {
  transform: translate3d(22px, 2px, 0);
}

.e-control {
  padding: 0;
}
/* The folowing CSS is for our syncfusion dialog */
.e-dialog.e-widget-content {
  padding: 0;
}

.e-dialog .e-dlg-header-content {
  padding: 0;
}
.e-dialog .e-dlg-header {
  padding: 0.5em;
  width: 100%;
  background-color: var(--green);
  color: white;
  font-size: 18px;
  font-weight: normal;
}

.e-dialog .e-dlg-content {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
}

/* To specify font size and color */
.custom-dialog-button {
  font-size: 14px;
  background-color: var(--green) !important;
  color: white !important;
  padding: 0.5em 1em !important;
  border-radius: 6px !important;
}
