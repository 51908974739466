@media (max-width: 640px) {
  .accordion__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
    object-fit: fill;
  }

  .accordion__item:last-of-type {
    margin-bottom: 0;
  }

  .accordion__titleGroup {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .accordion__title {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 300;
    color: #121a2d;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 0 5px 0;
    margin: 0;
  }

  .accordion__title--contentHide {
    padding: 0;
  }

  .accordion__content {
    position: relative;
    z-index: 50;
    transform-origin: top;
    transform: scaleY(1);
    transition: transform 0.26s ease;
    margin-left: 24px;
    height: 100%;
    margin-top: 30px;
  }

  .accordion__content--hide {
    transform-origin: top;
    transform: scaleY(0);
    transition: transform 0.26s ease;
    margin-left: 24px;
    height: 0;
    margin-top: 0px;
  }

  .accordion__toggleIcon {
    margin-right: 22px;
    width: 4px;
    height: 36px;
    border-radius: 4px;
  }
}

@media (min-width: 641px) {
  .accordion__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    width: 100%;
    object-fit: fill;
  }

  .accordion__item:last-of-type {
    margin-bottom: 0;
  }

  .accordion__titleGroup {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .accordion__title {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: 300;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 0 5px 0;
    margin: 0;
  }

  .accordion__title--contentHide {
    padding: 0;
  }

  .accordion__content {
    position: relative;
    z-index: 10;
    transform-origin: top;
    transform: scaleY(1);
    transition: transform 0.26s ease;
    margin-left: 24px;
    height: 100%;
    margin-top: 30px;
  }

  .accordion__content--hide {
    transform-origin: top;
    transform: scaleY(0);
    transition: transform 0.26s ease;
    margin-left: 24px;
    height: 0;
    margin-top: 0px;
  }

  .accordion__toggleIcon {
    margin-right: 22px;
    width: 4px;
    height: 36px;
    border-radius: 4px;
  }
}
