.PendingDispensingOrder__noDisplay {
  display: none;
}

.PendingDispensingOrder__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: "Poppins", sans-serif;
  color: #121a2d;
}

@media (max-width: 640px) {
  .PendingDispensingOrder__container {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    height: 100vh;
  }
}

@media (min-width: 641px) {
  .PendingDispensingOrder__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
  }
}

.PendingDispensingOrder__header {
  display: flex;
  width: 100%;
  align-items: center;
}

@media (max-width: 640px) {
  .PendingDispensingOrder__header {
    box-sizing: border-box;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0 16px 6px 16px;
    border-bottom: 1px solid #121a2d;
  }
}

@media (max-width: 640px) {
  .PendingDispensingOrder__header h1 {
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 1;
    color: #121a2d;
    margin: 0;
  }
}

@media (min-width: 641px) {
  .PendingDispensingOrder__header h1 {
    font-style: normal;
    font-weight: 400;
    color: #089bab;
    margin: 0;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .PendingDispensingOrder__header h1 {
    font-size: 28px;
    line-height: 1;
  }
}

@media (min-width: 1008px) {
  .PendingDispensingOrder__header h1 {
    font-size: 32px;
    line-height: 44px;
  }
}

@media (max-width: 640px) {
  .PendingDispensingOrder__header span {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1;
    color: #121a2d;
    margin-right: 12px;
  }
}

@media (min-width: 641px) {
  .PendingDispensingOrder__header span {
    margin: 0;
    font-style: normal;
    font-weight: 300;
    color: #454b60;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .PendingDispensingOrder__header span {
    font-size: 28px;
    line-height: 1;
  }
}

@media (min-width: 1008px) {
  .PendingDispensingOrder__header span {
    font-size: 32px;
    line-height: 44px;
  }
}

@media (max-width: 640px) {
  .PendingDispensingOrder__searchBarContainer {
    margin: 16px 16px 0 16px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .PendingDispensingOrder__searchBarContainer {
    flex-grow: 1;
    margin-top: 14px;
    margin-right: 20px;
  }
}

@media (min-width: 1008px) {
  .PendingDispensingOrder__searchBarContainer {
    width: 100%;
  }
}

@media (min-width: 641px) {
  .PendingDispensingOrder__button:hover {
    cursor: pointer;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .PendingDispensingOrder__button {
    margin-right: 10px;
  }
}

@media (min-width: 1008px) {
  .PendingDispensingOrder__button {
    margin-right: 20px;
  }
}

.PendingDispensingOrders__searchAndFilterRow {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 640px) {
  .PendingDispensingOrders__searchAndFilterRow {
    align-items: flex-end;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .PendingDispensingOrders__searchAndFilterRow {
    margin-top: 10px;
    align-items: center;
  }
}

@media (min-width: 1008px) {
  .PendingDispensingOrders__searchAndFilterRow {
    align-items: flex-end;
  }
}

.PendingDispensingOrders__tableContainer {
  display: flex;
  flex-direction: column;
  align-self: center;
}

@media (max-width: 640px) {
  .PendingDispensingOrders__tableContainer {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
    padding-top: 12px;
  }
  .PendingDispensingOrders__tableContainer::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 641px) {
  .PendingDispensingOrders__tableContainer {
    margin-top: 40px;
  }
}

.PendingDispensingOrders__orderInfo {
  display: flex;
}

@media (max-width: 640px) {
  .PendingDispensingOrders__orderInfo {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 641px) {
  .PendingDispensingOrders__orderInfo {
    align-items: flex-start;
    margin-left: 12px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .PendingDispensingOrders__orderInfo {
    margin-top: 25px;
  }
}

@media (min-width: 1008px) {
  .PendingDispensingOrders__orderInfo {
    margin-top: 50px;
  }
}

.PendingDispensingOrders__orderInfoItem {
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 1;
}

.PendingDispensingOrders__groupStatusIndicator {
  border-radius: 50%;
  background-color: #5cb85c;
}

@media (max-width: 640px) {
  .PendingDispensingOrders__groupStatusIndicator {
    width: 12px;
    height: 12px;
    margin: 0 6px 0 0;
    padding: 0;
  }
}

@media (min-width: 641px) {
  .PendingDispensingOrders__groupStatusIndicator {
    width: 8px;
    height: 8px;
  }
}

.PendingDispensingOrders__groupStatusIndicator--withError {
  background-color: #d9534f;
}

.PendingDispensingOrders__errorMsg {
  padding: 0;
  margin: 0;
  margin-top: 12px;
  font-size: 14px;
  line-height: 1;
  color: #d9534f;
}

.PendingDispensingOrders__errorName {
  position: absolute;
  left: 150px;
  top: 4px;
  font-size: 14px;
  line-height: 1;
  color: #d9534f;
  white-space: nowrap;
}


.PendingDispensingOrders__actionItemsContainer {
  position: relative;
  width: 40px;
}

.PendingDispensingOrders__actionItemsContainer:hover {
  cursor: pointer;
}

.PendingDispensingOrders_itemStatusIndicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #5cb85c;
}

.PendingDispensingOrders_itemStatusIndicator--withError {
  background-color: #d9534f;
}

.PendingDispensingOrders__buttons {
  display: flex;
}

@media (max-width: 640px) {
  .PendingDispensingOrders__buttons {
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: 30px;
    padding-top: 25px;
  }
}

@media (min-width: 641px) {
  .PendingDispensingOrders__buttons {
    flex: 1;
    align-items: flex-end;
    position: relative;
    margin-top: 38px;
    margin-left: 50px;
    margin-right: 68px;
  }
}

.PendingDispensingOrders__disabledButton {
  min-width: 213px;
  height: 48px;
  padding: 0 20px;
  outline: none;
  border: none;
  border-radius: 10px;
  font-style: "normal";
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  user-select: none;
  color: #fff;
  background-color: rgba(8, 155, 171, 0.5);
}

.PendingDispensingOrders__buttons--flexEnd {
  justify-content: flex-end;
}

.PendingDispensingOrders__buttons--flexSpaceBetween {
  justify-content: space-between;
}

.PendingDispensingOrders__ApproveRejectBtnGroup {
  display: flex;
}

.PendingDispensingOrders__popupContainer {
  position: relative;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .PendingDispensingOrders__popupContainer {
    top: var(--popupTopMd);
    right: var(--popupRightMd);
  }
}

@media (min-width: 1008px) {
  .PendingDispensingOrders__popupContainer {
    top: var(--popupTopLg);
    right: var(--popupRightLg);
  }
}

.PendingDispensingOrders__actionsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  position: relative;
  height: 30px;
  padding: 3px;
}

@media (max-width: 640px) {
  .PendingDispensingOrders__actionsContainer svg circle {
    fill: #5cb85c;
  }

  .PendingDispensingOrders__actionsContainer--withError svg circle {
    fill: #d9534f;
  }
}

.PendingDispensingOrders__actionsContainer:hover {
  cursor: pointer;
}

@media (max-width: 640px) {
  .PendingDispensingOrder__toggleSwitch {
    width: 30px;
  }
}

@media (max-width: 640px) {
  .PendingDispensingOrder__backButtonMobile {
    margin-top: 3px;
    margin-right: 16px;
  }
}

@media (max-width: 640px) {
  .PendingDispensingOrder__errorToggleGroup {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 640px) {
  .PendingDispensingOrder__headerTitleGroup {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 640px) {
  .PendingDispensingOrder__heading {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 640px) {
  .PendingDispensingOrder__orderDetailsGroup {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

@media (max-width: 640px) {
  .PendingDispensingOrder__splitGroup {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 16px;
    border-bottom: 1px solid #121a2d;
  }

  .PendingDispensingOrder__splitGroup--showSplitBtn {
    justify-content: space-between;
  }
}

@media (max-width: 640px) {
  .PendingDispensingOrder__splitBtn {
    background-color: transparent;
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    color: #089bab;
    font-weight: 500;
  }
}

.PendingDispensingOrder__dateRangeGroup {
  display: flex;
  flex-direction: column;
  width: 268px;
  margin-bottom: 12px;
  margin-right: 50px;
  box-sizing: border-box;
}

@media ((min-width: 1151px) and (max-width: 1567px)) {
  .PendingDispensingOrder__dateRangeGroup {
    position: absolute;
    left: 770px;
  }
}

@media (max-width: 640px) {
  .PendingDispensingOrder__dateRangeGroup {
    align-items: center;
  }
}

.PendingDispensingOrder__dateRangeLabel {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #089bab;
}

.PendingDispensingOrder__dateRange {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #121a2d;
  cursor: pointer;
}

@media (max-width: 640px) {
  .PendingDispensingOrder__dateRange {
    font-size: 14px;
    line-height: 1;
  }
}

.PendingDispensingOrder__overlay {
  position: absolute;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding: 75px;
  box-sizing: border-box;
}

.PendingDispensingOrder__modal {
  box-sizing: border-box;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.PendingDispensingOrders__saveButton {
  height: 30px;
}

.PendingDispensingOrders__orderInfoItemText {
  font-family: "Poppins", sans-serif;
  color: #089bab;
  line-height: 1;
  float: right;
}

.PendingDispensingOrders__orderInfoItemValue {
  font-family: "Poppins", sans-serif;
  color: #121a2d;
  line-height: 1;
}

.my-menu-item {
  height: 50px !important;
  line-height: 25px !important;
}
