
.Checkbox__label {
  color: var(--blueGray);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}
  

.Checkbox__label.disabled {
  color: var(--blackLight);
}

.Checkbox__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border-color: var(--green);
  border-style: solid;
  border-width: 1px;
  background-color: white;
  cursor: pointer;
  outline: none;
}


.Checkbox__button.disabled {
  border-color: var(--blackLight);
  cursor: default;
}

.Checkbox__button.checked {
  background-color: var(--green);
}

.Checkbox__button.checked.disabled {
  background-color: var(--blackLight);
}
