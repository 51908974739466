.CanisterTrayRatioGraph__PieChartContainer {
  display: flex;
  background-color: transparent;
  width: 450px;
  height: 288px;
  position: relative;
  overflow: hidden;
}

.CanisterTrayRatioGraph__VictoryPieContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.CanisterTrayRatio__noDataFoundMsg {
  position: absolute;
  font-family: inherit;
  font-style: normal;
  font-size: 14px;
  font-weight: normal;
  color: #909090;
  line-height: 1;
  margin: 0;
  padding: 0;
  top: 132px;
}

.CanisterTrayRatio__noDataFoundMsg--English {
  left: 174px;
}

.CanisterTrayRatio__noDataFoundMsg--Spanish {
  left: 138px;
}

.CanisterTrayRatio__noDataFoundMsg--French {
  left: 140px;
}

.CanisterTrayRatioGraph__VictoryPieContainer h2 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.CanisterTrayRatioGraph__VictoryPieContainer svg {
  margin-top: -30px;
}

.CanisterTrayRatioGraph__VictoryLegendContainer {
  position: absolute;
  right: 38px;
  top: 95px;
  width: 70px;
  padding-top: 10px;
  padding-left: 10px;
  height: 100px;
}

.CanisterTrayRatioGraph__VictoryLegendContainer svg {
  width: 300px !important;
}

.CanisterTrayRatioGraph__VictoryLegendContainer tspan {
  padding-bottom: 15px;
}

.CanisterTrayRatioGraph__timeRangeContainer {
  position: absolute;
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 1;
  color: #121a2d;
  bottom: 8px;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
}

@media (max-width: 640px) {
  .CanisterTrayRatioGraph__timeRangeContainer {
    justify-content: center;
    margin-left: -10px;
  }
}

@media (min-width: 641px) {
  .CanisterTrayRatioGraph__timeRangeContainer {
    justify-content: space-between;
  }
}

.CanisterTrayRatioGraph__timeRangeOption {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.CanisterTrayRatioGraph__timeRangeOption p {
  margin-top: 3px;
}

@media (max-width: 640px) {
  .CanisterTrayRatioGraph__timeRangeOption:not(:last-child) {
    padding-right: 7px;
  }
}

.CanisterTrayRatioGraph__squareContainer svg {
  width: 16px;
  fill: transparent;
  stroke: var(--green);
  padding-right: 3px;
}

.CanisterTrayRatioGraph__squareContainer--active svg {
  fill: var(--green);
}
