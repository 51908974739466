.CreateLabel__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 25px;
  overflow: auto;
}

.CreateLabel__container--center {
  align-items: center;
}

.CreateLabel__heading {
  margin: 0 0 50px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #454b60;
  width: 100%;
  text-align: center;
}

.CreateLabel__header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CreateLabel__buttonsContainer {
  display: flex;
  margin-top: 86px;
}

.CreateLabel__inputContainers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 334px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  padding: 0;
  margin: 0;
  line-height: 1;
  color: var(--green);
}

/* labelName container */
.CreateLabel__labelNameContainer {
  display: flex;
  flex-direction: column;
}

.CreateLabel__labelNameContainer input {
  width: 100%;
}

/* font container, font-size container */
.CreateLabel__labelTypeContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 38px;
  width: 100%;
}

.CreateLabel__machineTypeContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
  width: 100%;
}

.CreateLabel__labelTypeContainer div,
.CreateLabel__machineTypeContainer div {
  width: 100%;
}

.CreateLabel__labelsRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.CreateLabel__labelsLoader {
  color: #454b60;
  font-size: 14px;
  font-style: normal;
}

.CreateLabel__labelsTitle {
  margin: 0;
  color: #454b60;
  font-weight: 300;
  font-size: 32px;
  font-style: normal;
  line-height: 44px;
}

.CreateLabel__labelsPage {
  display: flex;
  flex-direction: column;
  gap: 30px 30px;
  height: 93%;
  box-sizing: border-box;
}

.CreateLabel__labelsPage--standard {
  min-height: 170px;
}

.CreateLabel__labelsPage--custom {
  min-height: 170px;
}

.CreateLabel__createButtonContainer button svg {
  stroke: var(--green);
}

.CreateLabel__labelsContainer {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 30px 30px;
  gap: 30px 30px;
  overflow: auto;
  padding-bottom: 5px;
}

.CreateLabel__nameError {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  color: var(--red);
  padding: 0;
  margin: -12px 0 0 0;
}

.CreateLabel__deleteWarningModal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 400px;
  height: 174px;
  border-radius: 5px;
  background-color: white;
  z-index: 10000000;
}

.CreateLabel__deleteWarningModalMessage {
  padding: 24px 18px 24px 18px;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  color: var(--black);
}

.CreateLabel__ModalButtonsGroup {
  display: flex;
  justify-content: center;
  align-items: center;
}
