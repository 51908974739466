.ScriptsByHourGraph__container {
  font-family: inherit;
  margin-top: -5px;
  width: 450px;
  height: 288px;
  box-sizing: border-box;
  overflow: hidden;
}

.ScriptsByHourGraph__noDataFoundMsg {
  position: absolute;
  font-family: inherit;
  font-style: normal;
  font-size: 14px;
  font-weight: normal;
  color: #909090;
  line-height: 1;
  margin: 0;
  padding: 0;
  top: 132px;
}

.ScriptsByHourGraph__noDataFoundMsg--English {
  left: 174px;
}

.ScriptsByHourGraph__noDataFoundMsg--Spanish {
  left: 138px;
}

.ScriptsByHourGraph__noDataFoundMsg--French {
  left: 140px;
}

.ScriptsByHourGraph__container h2 {
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  margin: 20px 0 0 0;
  padding: 0;
  line-height: 1;
}

.ScriptsByHourGraph__container svg {
  margin-top: -38px;
}

.ScriptsByHourGraph__noDataFoundMsg {
  position: absolute;
  font-family: inherit;
  font-style: normal;
  font-size: 14px;
  font-weight: normal;
  color: #909090;
  line-height: 1;
  margin: 0;
  padding: 0;
  top: 132px;
}

.ScriptsByHourGraph__noDataFoundMsg--English {
  left: 174px;
}

.ScriptsByHourGraph__noDataFoundMsg--Spanish {
  left: 138px;
}

.ScriptsByHourGraph__noDataFoundMsg--French {
  left: 140px;
}

.ScriptsByHourGraph__legendContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 16px;
  width: 434px;
}

.ScriptsByHourGraph__legendGroup {
  display: flex;
  align-items: center;
  font-family: "Segoe UI";
}

.ScriptsByHourGraph__legendGroup:nth-child(2) {
  padding-left: 15px;
}

.ScriptsByHourGraph__legendGroup p {
  line-height: 1;
  font-size: 13px;
  padding: 0 0 0 4px;
  margin: 0;
  color: #424242;
}

.ScriptsByHourGraph__mainDataLine {
  width: 14px;
  height: 3px;
  background-color: #318ce7;
}

.ScriptsByHourGraph__benchmarkLine {
  width: 14px;
  height: 3px;
  background-color: #e32636;
}
